<div class="doc-card"
     [class.selected]="isSelected"
     (mouseenter)="isHover = true"
     (mouseleave)="isHover = false"
>
  <div class="card-thumbnail">
    <div class="checkbox"
         *ngIf="isHover || item.isSelected"
         [class.no-checkbox]="noCheckbox"
    >
      <mat-checkbox [(ngModel)]="item.isSelected"
                    (change)="onSelected.emit()"
      ></mat-checkbox>
    </div>
    <div class="image"
         (click)="onFolder.emit()">
      <img [src]="getIconSource(item)" alt="folder file management">
    </div>
  </div>
  <div class="card-info">
    <div class="title">
      <div class="title-name">
        {{ title }}
      </div>
    </div>
    <div class="info">
      {{ date | date }}
    </div>
    <div class="info">
      <ng-content select="[content]"></ng-content>
    </div>

    <div class="action">
      <div class="action-list">
        <ng-content select="[action]"></ng-content>
      </div>
    </div>
  </div>
</div>
