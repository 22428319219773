import { TitleCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-status-chip",
  templateUrl: "./status-chip.component.html",
  styleUrls: ["./status-chip.component.scss"],
  imports: [TitleCasePipe]
})
export class StatusChipComponent {
  public color?: string;
  public status!: string;

  @Input({ alias: "status", required: true })
  public set setStatus(
    status:
      | "ASSIGNED"
      | "COMPLETED"
      | "DRAFT"
      | "IN_PROGRESS"
      | "NEW"
      | "NOT_STARTED"
      | "ON_HOLD"
      | "SCHEDULED"
      | "WAITING",
  ) {
    this.status = status.replace("_", " ");

    switch (status) {
      case "IN_PROGRESS":
      case "NEW":
        this.color = "blue";
        break;

      case "NOT_STARTED":
      case "ON_HOLD":
      case "WAITING":
        this.color = "brown";
        break;

      case "COMPLETED":
        this.color = "green";
        break;

      case "ASSIGNED":
      case "DRAFT":
      case "SCHEDULED":
        this.color = "grey";
        break;
    }
  }
}
