import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.loading.asObservable();

  constructor() { }

  set loader(value: boolean){
    this.loading.next(value)
  }

  get loader(): boolean {
    return this.loading.value;
  }
}
