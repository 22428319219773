<div class="card-widget-dashboard" [class.empty]="isEmpty">
  <app-roam-card
    [roamCardCompact]="cardCompact()"
    [title]="title" [titleCls]="'font-20px'">
    <ng-container cardContent *ngIf="!isLoading; else loader">
      <ng-container *ngIf="!isEmpty">
        <div class="mt-16 d-flex f-column h-100 flex-1">
          <ng-content select="[cardContent]"></ng-content>
        </div>
      </ng-container>

      <div>
        <div class="empty-state" *ngIf="isEmpty && !isLoading">
          <div>
            <app-image-view  [image]="image"
                             [height]="'77px'"
                             [width]="'80px'"></app-image-view>
          </div>
          <label class="tc-dust">
            {{ caption }}
          </label>
        </div>

        <div class="act justify-center gap-24 mt-20">

          <ng-container *ngIf="!isButton">
            <ng-content select="[actBtn]"></ng-content>
          </ng-container>

          <ng-container *ngIf="isButton">
            <app-roam-button [label]="btnActionText"
                             [class]="'btn-o-base flat'"
                             [full]="true"
                             [icon]="btnIcon"
                             (onClick)="clickAction()"
            ></app-roam-button>
            <app-roam-button *ngIf="!isEmpty"
                             [label]="btnOtherText"
                             [class]="'btn-primary flat'"
                             [full]="true"
                             (onClick)="clickOther()"
            ></app-roam-button>
          </ng-container>
        </div>
      </div>


    </ng-container>
  </app-roam-card>
</div>

<ng-template #loader>
  <div class="spinner-container">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
