import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { iconLib } from "@app/core/const/roam-icon";
import { AttachmentCardComponent } from "../attachment-card/attachment-card.component";
import { FileCardComponent } from "../file-config/file-card/file-card.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: 'app-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AttachmentCardComponent,
    FileCardComponent,
  ]
})
export class AttachmentUploadComponent {

  @Input()
  public fileCard: boolean = false;

  @Input()
  public insideUpload: boolean = true;

  @Output()
  public onUploadedFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @ViewChild('fileInput', {static: false}) fileInput!: ElementRef;


  public icon = iconLib;
  public files: File[] = [];

  public openFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  public selectedFile(event: any): void {
    const File = (event.target as HTMLInputElement).files;
    this.files.push(File![0]);
    this.onUploadedFiles.emit(this.files);
  }

  public deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.onUploadedFiles.emit(this.files);
  }

}
