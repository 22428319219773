<div>
    <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
    <div mat-dialog-title>
        <p>Filter your reservations.</p>   
    </div>
    <mat-dialog-content>
        <ul>
            <li *ngFor="let filter of filters">
              <mat-checkbox [(ngModel)]="filter.checked">
                {{ filter.name }}
              </mat-checkbox>
            </li>
        </ul>
    </mat-dialog-content>
    <div class="text-center">
        <button mat-button class="view-more">Save</button>
    </div>
</div>