import { Pipe, PipeTransform } from '@angular/core';
import { currencyFormatter } from "@app/core/helper/currency.helper";

@Pipe({
  name: 'currencyAmount'
})
export class CurrencyAmountPipe implements PipeTransform {

  transform(amount: string | number): string {
    if(amount) {
      return currencyFormatter(amount)!
    } else {
      return '-'
    }
  }

}
