import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { iconLib } from "@app/core/const/roam-icon";

export const docActions: IIdNameIcon[] = [
  {
    id: 'link',
    name: 'Create & Copy Shared Link',
    icon: iconLib.link.src,
  },
  {
    id: 'share',
    name: 'Share',
    icon: iconLib.share.src,
  },
  {
    id: 'ai',
    name: 'Ask AI',
    icon: iconLib.roamAiBlack.src,
  }
]
