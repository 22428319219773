import { Injectable } from '@angular/core';
import { IChat } from '@app/shared/interfaces/chat.interface';
import { BehaviorSubject } from 'rxjs';
import { IMessageReceive } from "@app/shared/interfaces/message-receive.interface";


@Injectable({
  providedIn: 'root'
})
export class ChatActionService {

  public openMessage: BehaviorSubject<IChat | null> = new BehaviorSubject<IChat | null>(null);
  public openMessage$ = this.openMessage.asObservable();

  public receivedNewMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updatedMessages: BehaviorSubject<IChat[]> = new BehaviorSubject<IChat[]>([]);
  public updatedMessageCloseState: BehaviorSubject<IMessageReceive | null> = new BehaviorSubject<IMessageReceive | null>(null);

  // Responsive Switch Layout
  public openAssistance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMenuView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
}
