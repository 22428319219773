import { RoamDateFormatterInterface } from "@app/shared/pipes/date-time-pipe/interface/roam-date-formatter.interface";
import { formatDate } from "@angular/common";
import { DateFormatterParams } from "@app/pages/calendar-roam/shared/formater/calendar-date-formatter/calendar-date-formatter.interface";

export class RoamDateAngularDateFormatter implements RoamDateFormatterInterface{

  // Format: 25/12/2023
  dateSlash({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'MM/dd/yyyy', locale )
  }

  dateMMDDComa({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'MMM dd, YYYY', locale )
  }

  dateMMMMDDComa({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'MMMM dd, YYYY', locale )
  }

  timeAMPM({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'hh:mm a', locale)
  }
}
