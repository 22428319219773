import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";
import { MatTooltipModule } from "@angular/material/tooltip";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-favorite',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    InlineSVGModule
  ],
  template:`
    <div class="align-center c-pointer">
      <div #tooltip="matTooltip"
           [matTooltip]="'Add to Favorite'"
           [matTooltipPosition]="'above'"
           [matTooltipClass]="'tooltip-caret'"
           [matTooltipDisabled]="isStarred"
      >
            <span class="d-flex star"
                  [class.starred]="isStarred"
                  [inlineSVG]="iconStar"
                  (click)="addToFavorite()"
            ></span>
      </div>
    </div>
  `,
  styles: [
    `
      .report-star {
        border: 1px solid transparent;
        border-bottom-color: #D6D3D7;
        padding: 0.5rem 0;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      .report-star:hover {
        background: #F9F9F9;
      }

      .report-star.selected {
        border-radius: 8px;
        border-color: #91466A;
        background: #F9F6F8;
      }

      h6 {
        position: relative;
        left:0;
        transition: left 0.3s ease;
      }

      .selected h6 {
        left: 1rem;
      }

      .star{
        fill: none;
        stroke: #cccccc;
      }

      .starred{
        fill: #876486;
        stroke: #876486;
      }
    `
  ]
})
export class FavoriteComponent {

  @Input()
  public id: string = '';

  @Input()
  public name: string = '';

  @Input()
  public isStarred: boolean = false;

  @Input()
  public isChecked: boolean = false;

  @Output()
  public onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  public iconStar = `${ROAM_IMAGE_SVG}/ic-favorite.svg`;

  public addToFavorite(): void {
    this.onClick.emit(!this.isStarred)
  }
}
