import { Component, Input } from '@angular/core';
import { iconLib } from "@app/core/const/roam-icon";
import { commentIconMenu } from "@app/shared/components/comment/shared/const";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

  @Input()
  public user!: string

  @Input()
  public picture!: string;

  @Input()
  public recentTime!: string;

  @Input()
  public isPrivate!: boolean;

  @Input()
  public comment!: string;

  public icon = iconLib;
  public menus: IIdNameIcon[] = commentIconMenu;
}
