import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAssociationDocument } from "../../../../shared/interface";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports:[CommonModule],
  selector: "app-document-action-file",
  templateUrl: "./document-action-file.component.html",
  styleUrls: ["./document-action-file.component.scss"],
})
export class DocumentActionFileComponent implements OnInit {
  @Input()
  public fileSelected: IAssociationDocument[] = [];

  @Output()
  public onReset: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  public reset(): void {
    this.onReset.emit();
  }
}
