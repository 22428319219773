import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-base-cell-table",
  template: "",
})
export abstract class BaseCellTableComponent {
  @Input() content: any;
  @Input() source: any;
  @Input() cell: any;

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  refresh() {}
}
