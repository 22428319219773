import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslatePipe } from "@app/shared/pipes/translate.pipe";

@Component({
  standalone: true,
  selector: "app-left-side-bar",
  templateUrl: "./left-side-bar.component.html",
  styleUrls: ["./left-side-bar.component.scss"],
  imports: [
    CommonModule,
    TranslatePipe,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatExpansionModule,
  ],
})
export class LeftSideBarComponent implements OnInit {
  @Input() properties: any[] = [];
  @Input() isReservationDetails: boolean = false;
  @Input() isCalendarDetails: boolean = false;

  @Output() expandedProperties: EventEmitter<string[]> = new EventEmitter();

  search: string = "";
  openedProperties: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  setProperty(id: string) {
    this.openedProperties.push(id);
    this.expandedProperties.emit(this.openedProperties);
  }

  removeProperty(selectedId: string) {
    this.openedProperties = this.openedProperties.filter(
      id => id !== selectedId
    );
    this.expandedProperties.emit(this.openedProperties);
  }
}
