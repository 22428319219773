import { Component } from '@angular/core';
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { toolbarComment } from "@app/shared/components/comment/shared/const";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-comment-chat-box',
  templateUrl: './comment-chat-box.component.html',
  styleUrls: ['./comment-chat-box.component.scss']
})
export class CommentChatBoxComponent {
  public toolbars: IIdNameIcon[] = toolbarComment;
  public icon = iconLib;
}
