<div mat-dialog-title>
  <div class="justify-between">
    <h1 class="fw-500">
      Share {{ title }}
    </h1>
    <div class="h-24 c-pointer" mat-dialog-close>
      <roam-icon name="close"></roam-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="ptb-20">
    <h6 class="font-14px tc-black fw-500">Share Link</h6>
    <div class="mt-4 d-flex gap-8">
      <input-group class="flex-1"/>
      <button-radius name="send"/>
    </div>
  </div>
</div>
