import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileCardComponent } from './file-card/file-card.component';
import { VideoCardComponent } from './video-card/video-card.component';
import { FileCardBarComponent } from './file-card-bar/file-card-bar.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { PipeModule } from "@app/shared/pipes/pipe.module";
import { FavoriteComponent } from "@app/shared/components/favorite/favorite.component";
import { FileFavoriteComponent } from './file-favorite/file-favorite.component';
import { FolderFavoriteComponent } from './folder-favorite/folder-favorite.component';
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { EllipsisModule } from "@app/shared/pipes/ellipsis/ellipsis.module";
import { MatMenuModule } from "@angular/material/menu";
import { ClickModule } from "@app/shared/directives/click/click.module";
import { FileViewComponent } from "@app/shared/components/file-config/file-view/file-view.component";
import { FileFolderListComponent } from "@app/shared/components/file-config/file-folder-list/file-folder-list.component";
import { FileCheckedComponent } from './file-checked/file-checked.component';
import { FileSliderComponent } from './file-slider/file-slider.component';


@NgModule({
  declarations: [    
    VideoCardComponent,
    FileCardBarComponent,
    FileFavoriteComponent,
    FolderFavoriteComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    PipeModule,
    FavoriteComponent,
    ImageViewComponent,
    EllipsisModule,
    MatMenuModule,
    ClickModule,
    FileViewComponent,
    FileFolderListComponent,
    FileSliderComponent,
    FileCardComponent
  ],
  exports: [
    FileCardComponent,
    FileCardBarComponent,
    VideoCardComponent,
    FileFavoriteComponent,
    FolderFavoriteComponent,
    ClickModule,
    FileViewComponent,
    FileFolderListComponent,
    FileSliderComponent
  ]
})
export class FileConfigModule { }
