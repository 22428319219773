import { Component } from '@angular/core';
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-button-customize',
  template: `
    <button class="btn btn-o-black">
      <span class="icon-cus">
        <img [src]="icon.setting.src" [alt]="icon.setting.alt">
      </span>
      <span class="tc-black">Customize Dashboard</span>
    </button>
  `,
  styles: [
      `button {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      border-radius: 24px;
      height: 48px;
      padding-right: 24px;
      padding-left: 24px;
      border: 0.3px solid #aaaaaa;
    }

    .icon-cus {
      width: 20px;
      height: 20px;
    }
    `
  ]
})
export class ButtonCustomizeComponent {

  public icon = iconLib;
}
