import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { OverlayModule } from "@angular/cdk/overlay";
import { RoamSidebarSubmenuComponent } from "@app/shared/components/roam-layout/roam-sidebar-menu/roam-sidebar-submenu/roam-sidebar-submenu.component";
import { DEVICE_MIN_RESOLUTION_MENU } from "@app/core/const/app-roam.const";
import { getSubMenusBySlug } from "@app/utils/data/menu";
import { Router, RouterModule } from "@angular/router";
import { MenuActionService } from "@app/pages/main/shared/services/menu-action.service";

@Component({
  selector: 'app-roam-sidebar-menu-item',
  standalone: true,
  imports: [CommonModule, InlineSVGModule, OverlayModule, RoamSidebarSubmenuComponent, RouterModule],
  template:`
    <a class="menu-item"
       [class.active]="isOpenMenu"
       cdkOverlayOrigin
       #openMenu="cdkOverlayOrigin"
       (click)="openSubMenu()"
    >
      <div
        class="icon inactive"
        [inlineSVG]="assetsPath + menu?.slug + '.svg'"
      ></div>
      <div class="menu-item-name">{{ menu.name }}</div>
    </a>

    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="openMenu"
      [cdkConnectedOverlayOpen]="isOpenMenu"
      (backdropClick)="isOpenMenu = false"
      [cdkConnectedOverlayPositions]="[
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 12,
        offsetY: -32
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom'
      }
    ]"
      cdkConnectedOverlayPush="true"
      [cdkConnectedOverlayViewportMargin]="7"
    >
      <app-roam-sidebar-submenu [pageName]="pageName"
                                [subMenus]="subMenus"
                                [slug]="menu.slug"
                                (onNavigate)="navigateTo($event)"/>
    </ng-template>

  `,
})
export class RoamSidebarMenuItemComponent implements OnInit{

  @Input()
  public pageName!: string;

  @Input()
  public menu: any;

  @Input()
  public subMenus: any[] = [];

  @Input()
  public isSubmenu: boolean = true;

  @Output()
  public onNavigate: EventEmitter<any> = new EventEmitter();

  @Output()
  public updateIndex: EventEmitter<any> = new EventEmitter<any>();

  public isOpenMenu!: boolean;
  public assetsPath = "./assets/svg/sidenav/";

  constructor(
    private router: Router,
    public menuAct: MenuActionService) {}

  ngOnInit(): void {
    this.validateOpenedMenu();
  }

  public openSubMenu(): void {
    this.subMenus =  getSubMenusBySlug(this.menu.slug);
    this.isOpenMenu = window.innerWidth <= DEVICE_MIN_RESOLUTION_MENU && this.isSubmenu && this.subMenus.length > 0;
  }

  public navigateTo(type: string): void {
    this.router.navigate([`${this.menu.slug}/${type}`]);
    this.isOpenMenu = false;
    this.updateIndex.emit();
    this.subMenus = [];
  }

  private validateOpenedMenu(): void {
    this.menuAct.openSubMenu.subscribe(type => {
      if(type.slug === this.menu.slug) {
        this.openSubMenu();
      }
    })
  }
}
