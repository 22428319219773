import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";

export const docPreviewHeaderIcon: IIdNameIcon[] = [
  {
    id: 'comment',
    name: 'Comment',
    icon: `${ROAM_IMAGE_SVG}/ic-comment.svg`,
    active: true
  },
  {
    id: 'comment',
    name: 'Comment',
    icon: `${ROAM_IMAGE_SVG}/ic-t-download.svg`,
    active: false
  },
  {
    id: 'comment',
    name: 'Comment',
    icon: `${ROAM_IMAGE_SVG}/ic-t-link.svg`,
    active: false
  },
  {
    id: 'comment',
    name: 'Comment',
    icon: `${ROAM_IMAGE_SVG}/ic-t-trash.svg`,
    active: false
  }
]
