import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from './ellipsis.pipe';
import { TrimTextDirective } from './trim-text.directive';



@NgModule({
  declarations: [
    TrimTextDirective
  ],
  imports: [
    CommonModule,
    EllipsisPipe
  ],
  exports: [
    EllipsisPipe,
    TrimTextDirective
  ]
})
export class EllipsisModule { }
