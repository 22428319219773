import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTrimText]'
})
export class TrimTextDirective {

  @Input()
  public characterLength: number = 250;

  private originalText: string = '';
  private trimmedText: string = '';
  private isTrimmed: boolean = true;
  private showMoreButton: HTMLButtonElement | null= null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.originalText = this.el.nativeElement.innerText;
    this.trimmedText = this.originalText.substring(0, this.characterLength);


    if(this.originalText.length > this.characterLength) {
      this.el.nativeElement.innerText = `${this.trimmedText}...`;
      this.createShowMoreButton();
    }
  }

  private createShowMoreButton(): void {
    this.showMoreButton = this.renderer.createElement('a');
    this.showMoreButton?.classList.add('read-more');
    this.renderer.setProperty(this.showMoreButton, 'innerText', 'Read More');
    this.renderer.listen(this.showMoreButton, 'click', () => this.toggleReadMore());

    this.renderer.appendChild(this.el.nativeElement, this.showMoreButton);
  }

  private toggleReadMore(): void {
    if (this.isTrimmed) {
      this.el.nativeElement.innerText = this.originalText;
      this.showMoreButton?.classList.add('show-less');
      this.renderer.setProperty(this.showMoreButton, 'innerText', 'Show Less');
    } else {
      this.el.nativeElement.innerText = `${this.trimmedText}...`;
      this.showMoreButton?.classList.remove('show-less');
      this.renderer.setProperty(this.showMoreButton, 'innerText', 'Read More');
    }
    this.renderer.appendChild(this.el.nativeElement, this.showMoreButton);
    this.isTrimmed = !this.isTrimmed;
  }

  ngOnDestroy() {
    if (this.showMoreButton) {
      this.showMoreButton.remove();
    }
  }

}
