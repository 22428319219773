import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
  @Output()
  public onSubmit: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;
  public text!: IConfirmationDialog;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { confirmation: IConfirmationDialog },
  ) {
    this.text = this.data.confirmation;
  }

  public submit(): void {
    this.dialogRef.close(true);
  }
}
