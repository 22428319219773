import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IDocument } from '@app/shared/interfaces';

@Component({
  standalone: true,
  selector: 'app-document-model',
  templateUrl: './document-model.component.html',
  styleUrls: ['./document-model.component.scss'],
  imports: [MatIconModule]
})
export class DocumentModelComponent implements OnInit {
  @Input() document: IDocument = {
    header: 'Attachment',
    title: 'Lease 2032',
    size: '125kb'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
