import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  MatCalendarCellCssClasses,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { CalendarHeaderComponent } from "../calendar-header/calendar-header.component";

@Component({
  standalone: true,
  selector: "app-roam-calendar",
  templateUrl: "./roam-calendar.component.html",
  styleUrls: ["./roam-calendar.component.scss"],
  imports: [CommonModule, MatDatepickerModule],
})
export class RoamCalendarComponent implements OnInit {
  @Input() filterDates: Date[] = [];
  @Output() onSelectDateShow: EventEmitter<string> = new EventEmitter();
  @Output() onSelectDate: EventEmitter<string> = new EventEmitter();

  calendarHeader = CalendarHeaderComponent;

  constructor() {}

  ngOnInit(): void {}

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.filterDates.some((d, i) => {
        return (
          d.getDate() === date.getDate() &&
          d.getMonth() === date.getMonth() &&
          d.getFullYear() === date.getFullYear()
        );
      });
      return highlightDate ? "busy-date" : "";
    };
  }

  onDateChange(event: Date | null) {
    if (event) {
      const busyDay = this.filterDates.find(
        date => date.getDate() === new Date(event).getDate()
      );

      if (busyDay) {
        const month = event.toLocaleString("default", { month: "short" });
        const day = event.getDate();
        const year = event.getUTCFullYear();

        const selectedDateData = month + " " + day + ", " + year;
        this.onSelectDate.next(selectedDateData);

        setTimeout(() => {
          this.onSelectDateShow.emit("");
        });
      }
    }
  }
}
