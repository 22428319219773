import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";

@Component({
  selector: 'app-card-list-image',
  standalone: true,
  imports: [
    CommonModule,
    ImageViewComponent
  ],
  template:`
    <div class="card-content-image">
      <div class="label" *ngIf="isLabel">New</div>
      <div class="flex-1 content-one"
           [class.mt-4]="isLabel">
        <ng-content select="[cardContent]"></ng-content>
      </div>
      <div class="image"
           [style.backgroundImage]="'url('+image+')'"
      >
      </div>
    </div>
  `,
  styles:[
    `
      .card-content-image .label {
        position: absolute;
        top: 0;
        left: 0;
        background: #876486;
        padding: 4px 8px;
        border-radius: 8px 0 8px 0;
        font-size: 12px;
        color: #ffffff;
      }

      .card-content-image {
        position: relative;
        height: 175px;
        display: flex;
        border-radius: 8px;
        padding: 1.5rem 1rem;
        box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.12);
        overflow: hidden;
      }

      .card-content-image .image {
        width: 25%;
        margin: -1.5rem -1rem -1.5rem 0;
        background-color: #CAC0CE80;
      }

      .card-content-image .content-one {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .card-content-image .image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left center;
      }

    `
  ]
})
export class CardListImageComponent {

  @Input()
  public isLabel: boolean = true;

  @Input()
  public image: any = `https://cdn2.hubspot.net/hub/504/file-2325108055-jpg/images/condo_clubhouse_doors_shut_082914-resized-600.jpg`;
}
