<div mat-menu-item>
    <div stopPropagate>
        <div class="justify-between">
            <button mat-button>
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <span class="roam-title">Team</span>
            <button mat-button>
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <mat-form-field class="filter-menu-search roam-field" appearance="outline">
            <input type="text" class="roam-text" matInput placeholder="Search member" name="search">
        </mat-form-field>
        <div class="filter-list">
            <span class="roam-title label-title">Team member</span>
            <div class="label align-center gap-15" *ngFor="let member of taskFilterMembers; let i = index">
                <div [class.active]="member.active" class="task-details-teams-btn roam-text">
                    <button  (click)="onTeamMemberClick(i)" mat-button class="justify-between">
                        <span class="roam-title">{{member.member}}</span>
                        <img *ngIf="member.active" src="/assets/svg/check-white.svg" alt="" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
