import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib, logo } from "@app/core/const/roam-icon";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { NgIf } from "@angular/common";

@Component({
  standalone: true,
  imports: [
    RoamButtonComponent,
    InlineSVGModule,
    RoamIconComponent,
    NgIf
  ],
  selector: 'app-header-roam-one',
  template:`
    <div class="header-one">
      <div class="align-center gap-8">
        <span class="d-flex" [inlineSVG]="logo"></span>
        <h1></h1>
      </div>
      <div>
        @if(rightContent) {
          <ng-content select="[buttonRight]"></ng-content>
        } @else {
          <ng-container>
            <app-roam-button *ngIf="!enableClose"
                            [label]="'Save & Exit'"
                            [class]="'btn-o-black fw-600 tc-black'"
                            (onClick)="onConfirm.emit()"
            ></app-roam-button>
            
            @if(enableClose) {
              <div>
                <roam-icon class="c-pointer" name="close" (onClick)="onClose.emit()"/>
              </div>
            }
          </ng-container>
        }
      </div>
    </div>
  `,
  styles:[
      `
      .header-one {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 40px;
        background-color: #f8f9fd;
        box-shadow: 4px 4px 10px 0 rgba(23, 73, 77, 0.03);
        z-index: 5;
      }

      .header-one h1 {
        font-size: 27px;
        padding-top: 3px;
      }

    `
  ]
})
export class HeaderRoamOneComponent {

  @Input()
  public rightContent: boolean = false;

  @Input()
  public enableClose!: boolean;

  @Output()
  public onConfirm: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();

  public logo = logo.roamOutline.src;
  public icon = iconLib;
}
