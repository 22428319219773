import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";
import { colorStatus } from "@app/core/utils/color.utils";

@Directive({
  standalone: true,
  selector: '[appFontColor]',
})
export class FontColorDirective implements OnChanges{

  @Input()
  public status!: string | number;

  @Input()
  public isBackground!: boolean;

  @Input()
  public isBgOpacity!: boolean;

  @Input()
  public size: 'md' | 'default' = 'default'

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: any): void {
    if(!changes['status']?.isFirstChange()) {
      this.colorConfig();
    }
  }

  ngOnInit() {
    this.colorConfig();
    this.renderer.setStyle(this.el.nativeElement, 'width', 'max-content');
    this.renderer.setStyle(this.el.nativeElement, 'borderRadius', '4px');
    this.renderer.setStyle(this.el.nativeElement, 'padding', '2px 8px');
    this.renderer.setStyle(this.el.nativeElement, 'fontSize', '12px');
    this.renderer.setStyle(this.el.nativeElement, 'fontWeight', '500');

    if(this.size === 'md') {
      this.setSizeMd()
    }
      
  }

  private colorConfig(): void {
    if(this.isBackground) {
      this.setBackgroundColor()
    } else if (this.isBgOpacity) {
      this.setBgOpacity()
    } else {
      this.setFontColor();
    }
  }

  private setSizeMd(): void {
    this.renderer.setStyle(this.el.nativeElement, 'padding', '8px 16px');
    this.renderer.setStyle(this.el.nativeElement, 'borderRadius', '12px');
    this.renderer.setStyle(this.el.nativeElement, 'fontSize', '18px');
  }

  private setFontColor() {
    const color = colorStatus.get(this.status.toString().toUpperCase());
    this.renderer.setStyle(this.el.nativeElement, 'color', color);
  }

  private setBgOpacity() {
    this.setFontColor();
    this.renderer.setStyle(this.el.nativeElement, 'background', this.getBgColor(this.status.toString().toUpperCase()));
  }

  private setBackgroundColor() {
    const bgColor = colorStatus.get(this.status);
    this.renderer.setStyle(this.el.nativeElement, 'background', bgColor);
    this.renderer.setStyle(this.el.nativeElement, 'color', '#ffffff');
  }

  public getBgColor(status: string | number, alpha: number = 0.2) {
    let hex = colorStatus.get(status) || "#fffffff";
    hex = hex.replace("#", "");

    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

}
