import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'text-icon-bg',
  standalone: true,
  imports: [
    CommonModule,
    RoamIconComponent
  ],
  template:`
    <div class="align-center gap-12">
      <div class="icon-bg">
        <roam-icon [name]="icon" color="primary" size="26"/>
      </div>
      <div>
       <ng-content></ng-content>
      </div>
    </div>
  `
})
export class TextIconBgComponent {

  @Input()
  public icon!: string;

  @Input()
  public label!: string;
}
