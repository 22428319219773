<div class="attachment"
     [class.justify-start]="files.length > 0"
>
  <input type="file" #fileInput id="uploadFile" (change)="selectedFile($event)"/>
  <ng-container *ngIf="files.length === 0">
    <span class="fw-600 tc-primary">Drag here</span> or
    <button mat-button class="upload-button"
            (click)="openFileUpload()">
      <img [src]="icon.upload.src" alt="Upload Icon" class="mr-8" />
      <span class="fw-400">Upload File</span>
    </button>
  </ng-container>

  <ng-container *ngIf="insideUpload">
    <ng-container *ngIf="files.length > 0">
      <div *ngFor="let file of files; let file_i = index">
        <ng-container *ngIf="!fileCard">
          <app-attachment-card [fileName]="file.name"
                               [type]="file.type"
                               [showDelete]="true"
                               (onDelete)="deleteFile(file_i)"
          ></app-attachment-card>
        </ng-container>
        <ng-container *ngIf="fileCard">
          <div class="position-relative">
            <div class="close" (click)="deleteFile(file_i)">
              <img [src]="icon.closeSolid.src" [alt]="icon.closeSolid.alt">
            </div>
            <app-file-card [name]="file.name"
                           [type]="file.name.split('.').pop() || ''"
                           [full]="true"
            ></app-file-card>
          </div>
        </ng-container>
      </div>
      <div class="tc-primary fw-500 c-pointer" (click)="openFileUpload()">+ Add File</div>
    </ng-container>
  </ng-container>
</div>
