import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-card-document',
  template:
      ` <div class="document">
            <a target="_blank" href="{{ url }}">
                <div class="icon">
                  <img [src]="extension === 'pdf' ? 'assets/svg/ic-file.svg' : 'assets/svg/ic-image.svg'" alt="file">
                </div>
                <span class="mt-10 tc-dust">{{ name }}</span>
            </a>
        </div>
        `
  ,
  styles: [
    `
      .document {
        min-width: 180px;
        background: #F9F9F9;
        border-radius: 0.5rem;
        padding: 1rem 1.5rem;
      }
    `,
    `.doc-item > * { width: calc(100% / 4)}`
  ]

})
export class CardDocumentComponent {

  @Input()
  public extension: string = 'file';

  @Input()
  totalDocuments: number = 0;

  @Input()
  public name: string = '';

  @Input()
  public url: string = '';
}
