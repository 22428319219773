import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'showNthArray'
})
export class ShowNthArrayPipe implements PipeTransform {

  transform(array: any[], count: number = 4): any[] {
    if(array && array.length > count) {
      return array.slice(0, count)
    }

    return array;
  }

}
