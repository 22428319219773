import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from "@angular/material/form-field";
import { iconLib } from "@app/core/const/roam-icon";
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-sidebar-menu-secondary',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
  ],
  templateUrl: './sidebar-menu-secondary.component.html',
  styleUrls: ['./sidebar-menu-secondary.component.scss']
})
export class SidebarMenuSecondaryComponent implements OnInit{

  @Output()
  public onCloseClicked = new EventEmitter<void>();

  @Output()
  public onMenuClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public onCloseOutside: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.onCloseOutside.emit(event)
    }
  }

  public title: string = '';
  public menus: any[] = [];
  public parentPath: string = '';
  public slug: string = '';
  public icon = iconLib;
  public keyword: string = '';

  private originalMenus: any[] = [];

  // Calculate Position
  public top: number = 80;

  constructor(
    private router: Router,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
      this.originalMenus = this.menus;
  }

  public navigateTo(menu: any): void {
    const path = menu.type === 'detail' ? `${this.parentPath}/${menu.type}/${menu.slug}` : `${this.parentPath}/${menu.type}`
    this.onMenuClicked.emit(menu.type);
    this.router.navigate([`${path}`]);
  }

  public closeButtonClick(): void {
    this.onCloseClicked.emit();
  }

  public searchAssociationList(): void {
    if (this.keyword.trim() === '') {
      this.menus = this.originalMenus;
    } else {
      this.menus = this.originalMenus.filter(item =>
        item.title.toLowerCase().includes(this.keyword.toLowerCase())
      );
    }
  }
}
