import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { SportPropagate } from "@app/shared/directives/stop-propagate.directive";

@Component({
  standalone: true,
  selector: "app-team-filters",
  templateUrl: "./team-filters.component.html",
  styleUrls: ["./team-filters.component.scss"],
  imports: [
    CommonModule,
    SportPropagate,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class TeamFiltersComponent implements OnInit {
  taskFilterMembers = [
    {
      active: false,
      member: "Jonathan",
    },
    {
      active: false,
      member: "Rowdy",
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onTeamMemberClick(memberIndex: number) {
    this.taskFilterMembers[memberIndex].active =
      !this.taskFilterMembers[memberIndex].active;
  }
}
