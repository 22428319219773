import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "phone"
})
export class PhonePipe implements PipeTransform {
  transform(rawNum: string): string {
    // Remove any non-digit characters
    const cleaned = ('' + rawNum).replace(/\D/g, '');

    // Check if the cleaned number has the right length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return rawNum;  // if the input doesn't match, return it as-is
  }
}
