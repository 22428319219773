import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { SidebarTriggerService } from "@app/shared/directives/sidebar-directive/sidebar-trigger.service";
import { ButtonComponent } from "@app/shared/components/button/button/button.component";

@Component({
  selector: 'filter-container',
  standalone: true,
  imports: [CommonModule, RoamIconComponent, ButtonComponent],
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss']
})
export class FilterContainerComponent {

  @Input()
  public showButton: boolean = true;

  @Output()
  public filter: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clear: EventEmitter<Event> = new EventEmitter<Event>();


  constructor(public sidebar: SidebarTriggerService) {
  }
}
