import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  standalone: true,
  selector: 'app-button-filter',
  imports: [CommonModule],
  template:`
    <button class="btn btn-o-glight tc-black align-center pr-16 pl-16"
            (click)="onClick.emit()"
    >
      <span class="mt-2 mr-6" *ngIf="isFilter">
        <img [src]="icon" alt="icon">
      </span>
      <span>{{ label }}</span>
  </button>
  `
})
export class ButtonFilterComponent {
  @Input()
  public label: string = 'Filter';

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  @Input()
  public icon: string = iconLib.filter.src;

  @Input()
  public class: string = '';

  @Input()
  public isFilter: boolean = true;
}
