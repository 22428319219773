<div class="position-relative menu-sidebar"
     [class.roam-main-sidebar]="main"
>
  <div
    class="nav-slide"
    [ngStyle]="{ visibility: index < 0 ? 'hidden' : 'visible' }"
    [style.top]="index === -1 ? 0 : topSlideMenu + 'px'"
  >
    <div class="menu-nav">
      <a class="menu-item" (click)="setMenu()">
        <span
          class="icon"
          [inlineSVG]="assetsPath + menus[index].slug + '.svg' || ''"
        >
        </span>
        <div class="menu-item-name">{{ menus[index].name || "" }}</div>
      </a>
    </div>
  </div>

  <div *ngFor="let menu of menus; let menu_i = index">
    <div
      class="menu-nav"
      (click)="navigate ? navigateRoute(menu_i) : switchMenu(menu_i)">
        <app-roam-sidebar-menu-item [menu]="menu"
                                    [pageName]="menu.name"
                                    [isSubmenu]="main"
                                    (updateIndex)="index = menu_i"/>
    </div>

  </div>

</div>

