import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-checkbox-select-user',
  templateUrl: './checkbox-select-user.component.html',
  styleUrls: ['./checkbox-select-user.component.scss']
})
export class CheckboxSelectUserComponent {

  @Input()
  public image: string | null = '';

  @Input()
  public name: string = '';

  @Input()
  public infoGroup: string = '';

  @Input()
  public multiple: boolean = true;

  @Output()
  public onChange: EventEmitter<Event> = new EventEmitter<Event>();

  @Input()
  public checked?: boolean = false;
}
