import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderRoamOneComponent } from './header-roam-one/header-roam-one.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    RoamButtonComponent,
    HeaderRoamOneComponent
  ],
  exports: [
    HeaderRoamOneComponent
  ]
})
export class HeaderModule { }
