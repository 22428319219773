import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { IModule } from "@app/shared/interfaces";
import { INavigateSidebar } from "@app/shared/interfaces/sidebar-menu.interface";
import { DEVICE_MIN_RESOLUTION_MENU } from "@app/core/const/app-roam.const";
import { MenuActionService } from "@app/pages/main/shared/services/menu-action.service";
import { IPageType } from "@app/shared/interfaces/page.interface";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { RoamSidebarMenuItemComponent } from "@app/shared/components/roam-layout/roam-sidebar-menu/roam-sidebar-menu-item/roam-sidebar-menu-item.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { isTabletView } from "@app/core/const/responsive";
import { getSubMenusBySlug, PageName } from "@app/utils/data/menu";

@Component({
  standalone: true,
  imports:[CommonModule, RouterModule, RoamSidebarMenuItemComponent, InlineSVGModule],
  selector: "app-roam-sidebar-menu",
  templateUrl: "./roam-sidebar-menu.component.html",
  styleUrls: ["./roam-sidebar-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoamSidebarMenuComponent implements OnChanges {
  @Input()
  public menus: IModule[] = [];

  @Input()
  public pageName!: string;

  @Output()
  public onIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public onNavigatePage: EventEmitter<INavigateSidebar> =
    new EventEmitter<INavigateSidebar>();

  @Input()
  public index: number = 0;

  @Input()
  public navigate: boolean = false;

  @Input()
  public menu: string = "";

  @Input()
  public subMenus: Partial<IPageType>[] = [];

  @Output()
  public menuChange: EventEmitter<string> = new EventEmitter();

  @Input()
  public main: boolean = true;

  public icons = iconLib;
  public assetsPath = "./assets/svg/sidenav/";
  public isOpenMenu!: boolean;

  constructor(public menuAct: MenuActionService) {}

  ngOnChanges(changes: SimpleChanges) {
    const change = changes["menus"] || changes["menu"];

    if (change && change.currentValue !== change.previousValue && this.menu) {
      this.index = this.menus.findIndex((each) => each.id === this.menu) ;
    }
  }

  get topSlideMenu(): number {
    return this.index * 60;
  }

  public switchMenu(index: number): void {
    this.index = index;
    this.onIndexChange.emit(this.index);
    this.menuChange.emit(this.menus[index].id);
  }

  public navigateRoute(index: number): void {
    this.subMenus = getSubMenusBySlug(this.menus[index]?.slug as PageName);
    if(this.subMenus.length === 0 || !isTabletView()) {
      this.index = index;
      this.onNavigatePage.emit({ index: index, slug: this.menus[index]?.slug });
    }
  }

  public setMenu(): void {
    if (window.innerWidth <= DEVICE_MIN_RESOLUTION_MENU) {
      this.menuAct.openSubMenu.next({slug: this.menus[this.index]?.slug});
    }
  }
}
