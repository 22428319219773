<div mat-dialog-title>
  <p class="fw-600">Share “File Name”`</p>
  <img
    src="/assets/svg/close-icon.svg"
    alt="Close Icon"
    class="c-pointer"
    mat-dialog-close
  />
</div>
<mat-dialog-content class="document-share-dialog-content">
  In Process
</mat-dialog-content>
