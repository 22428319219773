<div class="card-doc">
  <div class="file">
      <span class="close" (click)="deleteDelete(id)">
        <img [src]="icon.closeWhite.src" [alt]="icon.closeWhite.alt">
      </span>
    <div class="mt-2">
      <span [inlineSVG]="icon.pdf.src"></span>
    </div>
    <div>
      <div class="file-name">{{ name }}</div>
      <span class="extension tc-dust">
        {{ extension }}
      </span>
    </div>
  </div>
</div>
