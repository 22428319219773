import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { ButtonFavoriteComponent } from "@app/shared/components/button/button-favorite/button-favorite.component";
import { DocumentType } from "@app/shared/components/document-collection/shared/const/document.const";

export interface IdShow {
  id: string,
  show: boolean
}

@Component({
  selector: 'doc-action',
  standalone: true,
  imports: [CommonModule, RoamIconComponent, ButtonFavoriteComponent],
  template:`
    <div class="align-center gap-16">
      <ng-container *ngFor="let icon of configs">
        <div  *ngIf="!(isNoAi && icon.id === 'ai-roam')"
              (click)="onClick.emit(icon.id)">
          <roam-icon [name]="icon.id" size="24" ></roam-icon>
        </div>
      </ng-container>
      <button-favorite/>
    </div>
  `
})
export class DocActionComponent implements OnInit{
  @Input() type!: string;
  @Input() isNoAi!: boolean;
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();

  public configs: IdShow[] = [];
  public shows: string[] = ['link-hover', 'share', 'ai-roam'];
  public docType = DocumentType;

  ngOnInit(): void {
    this.setIcon();
  }

  private setIcon(): void {
   this.configs = this.shows.map(icon =>{
     return({
       id: icon,
       show: true
     })
   })
  }
}
