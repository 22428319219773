import { IContactTable } from "./interface/contact-table.interface";

export const textStatus = new Map([
  ['Active', '#2AB51E'],
  ['Invited', '#B5781E'],
  ['Denied', '#D40101']
])

export const SAMPLE_HOME_DATA:IContactTable[]  = [
  {
    name: 'Bruno Fernandes',
    association: 'Lorem ipsum is dummy text lorem',
    address: 'Lorem ipsum is dummy text lorem',
    email: 'Bruno.@gmail.com',
    phone: '012 3456789',
    date: '07/14/23',
    status: 'Active',
    isSelected: false,
    isHover: false,
  },
  {
    name: 'Bruno Fernandes',
    association: 'Lorem ipsum is dummy text lorem',
    address: 'Lorem ipsum is dummy text lorem',
    email: 'Bruno.@gmail.com',
    phone: '012 3456789',
    date: '07/14/23',
    status: 'Invited',
    isSelected: false,
    isHover: false
  },
  {
    name: 'Bruno Fernandes',
    association: 'Lorem ipsum is dummy text lorem',
    address: 'Lorem ipsum is dummy text lorem',
    email: 'Bruno.@gmail.com',
    phone: '012 3456789',
    date: '07/14/23',
    status: 'Invited',
    isSelected: false,
    isHover: false
  },
  {
    name: 'Bruno Fernandes',
    association: 'Lorem ipsum is dummy text lorem',
    address: 'Lorem ipsum is dummy text lorem',
    email: 'Bruno.@gmail.com',
    phone: '012 3456789',
    date: '07/14/23',
    status: 'Denied',
    isSelected: false,
    isHover: false
  },
  {
    name: 'Bruno Fernandes',
    association: 'Lorem ipsum is dummy text lorem',
    address: 'Lorem ipsum is dummy text lorem',
    email: 'Bruno.@gmail.com',
    phone: '012 3456789',
    date: '07/14/23',
    status: 'Active',
    isSelected: false,
    isHover: false
  }
];
