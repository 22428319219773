import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { CommonModule } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  standalone: true,
  imports: [CommonModule, MatMenuModule],
  selector: 'app-document-mobile-action-more',
  templateUrl: './document-mobile-action-more.component.html',
  styleUrls: ['./document-mobile-action-more.component.scss']
})
export class DocumentMobileActionMoreComponent {

  @Input()
  public isFile: boolean = false;

  @Output()
  public onClick: EventEmitter<string> = new EventEmitter<string>();

  public icon = iconLib;
  public iconCollections: IIdNameIcon[] = [
    {
      id: 'link',
      name: 'Create & Copy Shared Link',
      icon: iconLib.link.src,
    },
    {
      id: 'share',
      name: 'Share',
      icon: iconLib.share.src,
    },
    {
      id: 'ai',
      name: 'Ask AI',
      icon: iconLib.roamAiBlack.src,
    }
  ]
}
