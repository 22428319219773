import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-create-new-knowledge-base',
  template:`
    <div class="new-knowledge-base">
      <div class="justify-between">
        <h4 class="fw-500">Create New</h4>
        <div class="c-pointer" (click)="close()">
          <roam-icon name="close"></roam-icon>
        </div>
      </div>
      <div class="justify-start gap-8 mt-20">
        <div class="flex-1">
          <input type="text" [formControl]="kbName" class="kb-input" placeholder="Knowledge base name"
                 (keyup.enter)="save()"
          />
        </div>
        <button class="btn btn-primary mr-0 position-relative"
                (click)="save()">Save</button>
      </div>
    </div>
  `,
  styles:[`
    .new-knowledge-base {
      padding: 16px;
      border-radius: 8px;
      background-color: #EEEEEE;
    }

    .kb-input {
      width: 100%;
      border: none;
      padding: 12px 12px 11px 12px;
      border-radius: 8px;
    }

    .kb-input::placeholder {
      color: #949494;
      font-family: "Poppins", sans-serif;
    }
  `]
})
export class CreateNewKnowledgeBaseComponent {
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSave: EventEmitter<string> = new EventEmitter<string>();
  kbName = new FormControl('');
  save() {
    if (!this.kbName.value) {
      return;
    }
    this.onSave.emit(this.kbName.value);
    this.kbName.reset();
    this.close();
  }
  close() {
    this.onClose.emit();
  }
}
