import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-checkbox-card',
  template:`
    <label class="label-card" [for]="id" >
      <input class="label-card-input" type="checkbox" [name]="name" [id]="id">
      <span class="label-card-tile">
            {{ label }}
      </span>
    </label>
  `,
  styleUrls: ['./checkbox-card.component.scss']
})
export class CheckboxCardComponent {

  @Input()
  public id: any = null;

  @Input()
  public name: any = null;

  @Input()
  public label: any = null;

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter<any>();

  public change(): void {
    this.onChange.emit()
  }

}
