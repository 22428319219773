import { CommonModule } from "@angular/common";
import { Component, input, model, signal } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { FileExtensionPipe } from "@app/shared/pipes/file-extension.pipe";
import { FormsModule } from "@angular/forms";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";

export const fileActions: IIdNameIcon[] = [
  {
    id: 'more',
    name: 'More',
    icon: 'more-horizontal'
  },
  {
    id: 'favorite',
    name: 'Favorite',
    icon: 'favorite'
  },
  {
    id: 'link',
    name: 'Link',
    icon: 'link'
  },
  {
    id: 'share',
    name: 'Share',
    icon: 'share'
  }
]
@Component({
  standalone: true,
  imports: [
    CommonModule, MatCheckboxModule, RoamIconComponent,
    FileExtensionPipe, FormsModule],
  selector: "file-checked",
  template: `
    <div class="file-checked"
         [class.checked]="checked()"
         (mouseenter)="showCheckbox.set(true)"
         (mouseleave)="showCheckbox.set(false)"
    >
      <div class="checkbox-file" [class.d-block]="showCheckbox() || checked()">
        <mat-checkbox [(ngModel)]="checked"></mat-checkbox>
      </div>
      <div class="file-preview"></div>
      <div class="file-text">
        <div class="file-text-wrapper text-center">
          <span class="file-name">
            <roam-icon class="extension" [name]="url() | fileExtension" size="16" [inline]="true"></roam-icon>          
            {{ fileName() }}
          </span>
        </div>
        <div class="file-date">
          {{ date() | date}} by {{ author() }}
        </div>
      </div>
      <div class="file-action" [class.d-flex]="showCheckbox() || checked()">
        @for (action of actions; track $index) {
          <roam-icon [name]="action.icon" size="20"/>
        }
      </div>
    </div>
  `,
  styleUrl: `./file-checked.component.scss`

})
export class FileCheckedComponent {
  url = input<string>('');
  fileName = input<string>('');
  date = input<string>('');
  author = input<string>('');
  checked = model<boolean>();
  showCheckbox = signal<boolean>(false);
  actions: IIdNameIcon[] = fileActions;
}
