import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-edit-actions',
  templateUrl: './edit-actions.component.html',
  styleUrls: ['./edit-actions.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class EditActionsComponent implements OnInit {
  @Output() showEditMode: EventEmitter<boolean> = new EventEmitter();
  isEditMode: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onStartEdit() {
    this.isEditMode = true;
    this.showEditMode.emit(true);
  }

  onCancelEdit() {
    this.isEditMode = false;
    this.showEditMode.emit(false);
  }

  onSaveEdit() {
    this.isEditMode = false;
    this.showEditMode.emit(false);
  }

}
