import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";

@Component({
  selector: 'app-round-nav-lg',
  template:`
    <div class="round-nav"
         [class.left]="left"
         (click)="onClick.emit()">
      <span class="d-flex icon" [inlineSVG]="caretArrow" style="margin-left: -3px"></span>
    </div>
  `,
  styles:[
    `
    .round-nav {
      width: 56px;
      height: 56px;
      background: #ffffff;
      border-radius: 100%;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .round-nav.left {
      transform: rotate(-180deg);
    }
    `
  ]
})
export class RoundNavLgComponent {

  @Input()
  public left!: boolean;

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public caretArrow: string = `${ROAM_IMAGE_SVG}/ic-right.svg`;
}
