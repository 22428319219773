import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardListImageComponent } from "@app/shared/components/roam-card/card-list-image/card-list-image.component";
import { CardCheckedLabelComponent } from "@app/shared/components/roam-card/card-checked-label/card-checked-label.component";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CardListImageComponent,
    CardCheckedLabelComponent
  ],
  exports: [
    CardListImageComponent,
    CardCheckedLabelComponent
  ]
})
export class RoamCardModule { }
