import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'showAllArray'
})
export class ShowAllArrayPipe implements PipeTransform {

  transform(array: any[], count: number = 4): string | null{
    if(array ) {
      return `${array.length - count} more`
    }

    return null ;
  }
}
