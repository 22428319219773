<div>
  <div class="roam-card-two">
    <div>
      <header class="space-between">
        <div class="align-center gap-8">
          <roam-icon [src]="false" [name]="titleIcon"></roam-icon>
          <h6>{{ title }}</h6>
        </div>
        <div class="action align-center" (click)="onAct.emit()">
          <span> {{ action }} </span>
          <span class="d-flex" *ngIf="icon"><img [src]="icon" alt="action"></span>
        </div>
      </header>
      <div class="line"></div>
      <div class="body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="action-btn" *ngIf="btnAction">
        <ng-content select="[actionBtn]"></ng-content>
      </div>
    </div>
  </div>
</div>
