<table
  mat-table
  [dataSource]="dataSource"
  matSort
  class="mat-table__grey-border default"
  aria-describedby="invoice table"
>
  <ng-container *ngFor="let col of tableColumns; let col_i = index"
                [matColumnDef]="col.columnDef"
  >
    <ng-container>
      <th mat-header-cell *matHeaderCellDef
          [class.text-th-right]="col.textRight"
      >
      <ng-container *ngIf="!col.isCheckbox">
        {{ col.header }}
        {{ col.isCheckbox }}
      </ng-container>

        <ng-container *ngIf="col.isCheckbox">

          <div class="filter-list">
            <mat-checkbox
              class="check__file"
              [checked]="isSelectedAll"
              (change)="selectAllData($event.checked)"
            ></mat-checkbox>
          </div>

        </ng-container>
      </th>

      <td mat-cell *matCellDef="let element"
          [class.text-th-right]="col.textRight"
      >
        <ng-container [ngSwitch]="col?.type">
          <ng-container *ngSwitchCase="'hover'">
            <a class="c-pointer tc-black hover-underline"
               (click)="navigateDetail(element['id'])"
            >
              {{ col.cell(element) }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'submenu'">
            <ng-container [ngTemplateOutlet]="subMenu"
                          [ngTemplateOutletContext]="{label: {top: col?.topText(element), bottom: col?.bottomText(element)}}"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'status'">
            <ng-container [ngTemplateOutlet]="status"
                          [ngTemplateOutletContext]="{label: {status: col.cell(element), date: '17.08.23'}}"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'checkbox'">
            <div class="filter-list" *ngIf="element.isHover || element.isSelected">
              <mat-checkbox
                class="check__file"
                [(ngModel)]="element.isSelected"
                (change)="selectedRow()"
              ></mat-checkbox>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ col.cell(element) }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayColumns"
    [ngClass]="{ 'selected': row.isSelected }"
    (mouseover)="row.isHover = true"
    (mouseleave)="row.isHover = false"
  ></tr>
</table>

<mat-paginator
  [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="5"
  [length]="20"
>
</mat-paginator>

<ng-template let-label="label" #subMenu>
  <div>{{ label.top }}</div>
  <div>{{ label.bottom }}</div>
</ng-template>

<ng-template let-label="label" #status>
  <span [style.color]="statusConfig.get(label.status)">{{ label.status }}</span>
  <div>{{ label.date }}</div>
</ng-template>
