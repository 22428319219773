<div>
    <p class="details-header">{{ document.header }}</p>
    <div class="border document-card">
        <div class="justify-end">
            <mat-icon>close</mat-icon>
        </div>
        <div class="d-flex gap-32">
            <img src="assets/svg/pdf.svg" alt="pdf-file icon" class="pdf-icon">
            <div>
                <p class="details-title">{{ document.title }}</p>
                <p class="details-text">{{ document.size }}</p>
                <div class="d-flex gap-22">
                    <p class="details-title donwload-file">Download</p>
                    <p class="details-title replace-file">Replace</p>
                </div>
            </div>
        </div>
    </div>
</div>