<div mat-dialog-title>
  <div class="justify-between">
    <h1 class="fw-500">
      Share {{ title }}
    </h1>
    <div class="h-24 c-pointer" mat-dialog-close>
      <roam-icon name="close"></roam-icon>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="pt-20">
    <h6 class="text-label">Email Shared Link</h6>
    <div class="mt-4">
      <textarea class="area"
                placeholder="Add names or email addresses"
      ></textarea>
    </div>
    <div class="mt-16">
      <app-roam-text-area name="Message (Optional)" />
    </div>
  </div>
</div>

<mat-dialog-actions class="mat-dialog-roam-action gap-10">
  <div class="tc-black text-underline fw-500 font-14px">Cancel</div>
  <app-button label="Save"/>
</mat-dialog-actions>
