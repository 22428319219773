import { Component, EventEmitter, input, Input, Output } from "@angular/core";

@Component({
  selector: 'app-card-widget-dashboard',
  templateUrl: './card-widget-dashboard.component.html',
  styles: [

    `.act > app-roam-button {
      min-width: 180px;
    }

    .card-widget-dashboard .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex: 1;
    }

    .spinner-container {
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::ng-deep .card-widget-dashboard .card {
      min-height: 380px;
    }

    ::ng-deep .card-widget-dashboard .card .mat-mdc-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    ::ng-deep .card-widget-dashboard.empty .card .mat-mdc-card-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::ng-deep .act > app-roam-button button {
      height: 45px!important;
    }

    `
  ]
})
export class CardWidgetDashboardComponent {

  @Input()
  public image: string = '';

  @Input()
  public title: string = '';

  @Input()
  public caption: string = '';

  @Input()
  public isButton: boolean = true;

  @Input()
  public btnActionText: string = '';

  @Input()
  public btnIcon: {src: string, alt: string} = {src: '', alt: ''}

  @Input()
  public btnOtherText: string = '';

  @Input()
  public isEmpty: boolean = true;

  @Input()
  public isLoading: boolean = false;

  readonly cardCompact = input(true);

  @Output()
  public onBtnAction: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public onOtherAction: EventEmitter<Event> = new EventEmitter<Event>();

  public clickAction(): void {
    this.onBtnAction.emit();
  }

  public clickOther(): void {
    this.onOtherAction.emit();
  }

}
