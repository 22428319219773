<mat-drawer-container autosize>
  <mat-drawer-content>
    <div class="d-flex mr-8">
      <section class="main-content flex-1 p-32">
        <ng-content select="[content]"></ng-content>
      </section>

      <ng-container>
        <aside [class.flex-0]="!isExpand"
               [class.collapse]="!isExpand"
        >
          <div class="nav-show"
               (click)="expandMenuAction()">
              {{ isExpand ? '>' : '<' }}
          </div>
          <div [class.hide-sidebar]="!isExpand">
            <h6 class="font-12px fw-600 tc-grey-dark mt-10">Quick Action</h6>
            <div class="mt-16">
              <ng-content select="[quickAction]"></ng-content>
            </div>
          </div>
        </aside>
      </ng-container>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
