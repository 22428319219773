import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { getFileExtension, getFileIcon } from "@app/shared/components/file-config/shared/utils/file-word-masking.util";

@Component({
  selector: 'app-file-favorite',
  template:`
    <div>
      <div>
        <div class="file-favorite" >
          <div class="file-box"
               [class.is-show]="isFavorite || isOpened"
               (mouseleave)="closeMenu()"
          >
            <div class="align-center gap-8 file-info">
              <div class="star">
                <app-favorite (onClick)="onFavorite.emit($event)"
                              [isStarred]="isFavorite"
                ></app-favorite>
              </div>
              <div class="align-center gap-8" (mouseenter)="openMenu()">
                <span class="d-flex" [inlineSVG]="fileIcon"></span>
                <div class="file-name">
                  <div class="file-name-item">
                    <div class="name">
                      {{ fileName }}
                    </div>
                    <div class="font-12px tc-grey">
                      {{ extension }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="file-act" (click)="openMenu('menu')">
              <span class="d-flex svg-20" [inlineSVG]="icon.roamAiBlack.src"></span>
              <span class="d-flex svg-20" [inlineSVG]="icon.dotVertical.src"
                    [matMenuTriggerFor]="menu"
              ></span>
            </div>
          </div>
          <div class="file-viewer">
            <app-image-view [width]="'100%'" [height]="'160px'"
                            [image]="null"
                            [placeholderImage]="'ic-file'"
            ></app-image-view>
          </div>
        </div>
      </div>
    </div>

    <mat-menu #menu="matMenu" class="menu-action w-2" yPosition="below" xPosition="before">
      <div class="menu-action-item justify-between">
        <span>Action 1</span>
      </div>
    </mat-menu>

    <ng-template #emptyFile>
      <div class="empty-icon" >
        <span [inlineSVG]="icon.file.src"></span>
      </div>
    </ng-template>
  `,
  styles:[
      `
      .file-info {
        min-width: 0;
      }

      .file-name {
        display: flex
      }

      .file-name-item {
        flex: 1;
        min-width: 0;
        width: 147px;
      }

      .file-name-item .name {
        font-size: 14px;
        font-weight: 500;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: block;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 40px);
      }

      .file-favorite {
        padding: 12px 8px;
        border-radius: 8px;
        border: 1px solid #E8E4E9;
        cursor: pointer;
      }

      .file-box {
        padding: 0 8px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }

      /*.file-box:hover .star,*/
      /*.file-box:hover .file-act {*/
      /*  opacity: 1;*/
      /*}*/

      .file-box.is-show .star,
      .file-box.is-show .file-act {
        visibility: visible;
      }

      .star,
      .file-act {
        visibility: hidden;
      }

      .file-viewer {
        min-height: 160px;
        display: block;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #E8E4E9;
      }

      .file-act {
        border-radius: 8px;
        padding: 8px 12px;
        border: 1px solid #E8E4E9;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .file-act svg {
        width: 20px;
        height: 20px;
      }

      ::ng-deep .empty-icon svg {
        width: 100px;
        height: 100px;
      }

      ::ng-deep .file-viewer .img-center {
        background-position: top;
      }
    `
  ],

})
export class FileFavoriteComponent {

  @Input()
  public fileName!: string;

  @Input()
  public type!: string

  @Input()
  public isFavorite!: boolean;

  @Output()
  public onFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: Event): void {
    const targetElement = event.target as HTMLElement;
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      (<HTMLElement>document.querySelector('.file-box'))?.classList.remove('.is-menu')
      this.isOpened = false;
    }
  }

  public icon = iconLib;
  public isOpened!: boolean;

  constructor(private elementRef: ElementRef) {}

  get fileIcon(): string {
    const type = this.type ? this.type : this.fileName.split('.')[1];
    return getFileIcon(type);
  }

  get extension(): string{
    const type = this.type ? this.type : this.fileName.split('.')[1];
    return getFileExtension(type)
  }

  public openMenu(type: string = 'hover'): void {
    (<HTMLElement>document.querySelector('.file-box'))?.classList.remove('.is-menu')

    if(type === 'menu') {
      (<HTMLElement>document.querySelector('.file-box'))?.classList.add('is-menu');
      this.isOpened = true;
    } else {
      this.isOpened = true;
    }
  }

  public closeMenu(): void {
    const isOpenMenu = (<HTMLElement>document.querySelector('.file-box'))?.classList.contains('is-menu');
    if (isOpenMenu) {
      (<HTMLElement>document.querySelector('.file-box'))?.classList.remove('is-menu');
      return;
    }
    this.isOpened = false;
  }
}
