import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'menu-back-navigation',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <div class="align-center gap-8">
      <div class="c-pointer" (click)="onClick.emit()">
        <roam-icon name="chevron-left" size="20"></roam-icon>
      </div>
      <h5 class="fw-500 tc-black font-18px capitalized">
        {{ pageName }}
      </h5>
    </div>
  `,
})
export class MenuBackNavigationComponent {
  @Input()
  public pageName!: string;

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
