import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appActiveClass]'
})
export class ActiveClassDirective {

  @Input()
  public className: string = 'active'

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event']) onClick(event: Event) {
    const activeElements = document.querySelectorAll(`.${this.className}`);
    activeElements.forEach(element => {
      element.classList.remove(this.className)
    })
    this.el.nativeElement.classList.add(this.className);
  }
}
