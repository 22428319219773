import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { IconRoundComponent } from "@app/shared/components/roam-layout/icon-round/icon-round.component";
import { DocSharedMoreComponent } from "@app/shared/components/document-collection/doc-shared-more/doc-shared-more.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { dropdownLeftPosition } from "@app/core/const/overlay.const";

@Component({
  standalone: true,
  imports:[CommonModule, MatIconModule, IconRoundComponent,
           DocSharedMoreComponent, OverlayModule],
  selector: "app-document-action",
  templateUrl: "./document-action.component.html",
  styleUrls: ["./document-action.component.scss"],
})
export class DocumentActionComponent {
  @Input()
  public isSelected: boolean = false;

  @Input()
  public showAIAction: boolean = false;

  @Output()
  public clickAI: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clickDelete: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clickDownload: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clickKnowledgeBase: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clickSend: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public clickShare: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public layoutChange: EventEmitter<Event> = new EventEmitter<Event>();

  public fileLayout: boolean = true;
  public icon = iconLib;
  public showMore: boolean = false;
  public dropdownLeft = dropdownLeftPosition;

  public switchLayout() {
    this.layoutChange.emit();
    this.fileLayout = !this.fileLayout;
  }

  public showMoreAction() {
    this.showMore = !this.showMore;
  }
}
