import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontColorDirective } from './font-color.directive';
import { SeeMoreDirective } from './see-more.directive';

@NgModule({
  declarations: [
    SeeMoreDirective
  ],
  imports: [
    CommonModule,
    FontColorDirective
  ],
  exports: [
    FontColorDirective,
    SeeMoreDirective
  ]
})
export class TextDirectiveModule { }
