import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { FormControl, FormGroup } from "@angular/forms";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { KbActionService } from "@app/shared/components/knowledge-base/service/kb-action.service";

@Component({
  selector: 'app-knowledge-base-select',
  templateUrl: './knowledge-base-select.component.html',
  styles:[
    `
      .file-name-base {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .file-name-base:hover {
        background-color: #F5F5F5;
      }

      .file-name-base.selected {
        background-color: rgba(145, 70, 106, 0.1);
      }

      .knowledge-list {
        max-height: 50vh;
        overflow-y: auto;
      }

    `
  ]
})
export class KnowledgeBaseSelectComponent implements OnInit, AfterViewInit{

  @Input()
  public knowledgeBases: any[] = [];

  @ViewChild('openAddEvent', { read: ElementRef })
  roamSelectRef!: ElementRef;

  @Output() onSave: EventEmitter<string> = new EventEmitter<string>();

  public form!: FormGroup;
  public files: any[] = [];
  public selectedFiles: any = [];
  public isDropdown!: boolean;
  public dropdownWidth!: number;
  public dropdownPosition: ConnectionPositionPair[] = dropdownPosition;
  public isCreateNew!: boolean;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    public kbAction: KbActionService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      kbControl: new FormControl()
    })
  }

  get matTriggerText(): string {
    if (this.files.length > 0) {
      return this.files.length > 1 ? this.files?.length + ' files selected' : this.selectedFiles[0]?.name
    }
    return  '';
  }

  ngAfterViewInit(): void {
    if (this.roamSelectRef) {
      this.dropdownWidth = this.roamSelectRef.nativeElement.offsetWidth;
    }
    this.cdr.detectChanges();
  }

  public getKnowledgeBase(): void {

  }

  public selectedFile(file: any): void {
    this.form.get('kbControl')?.setValue(file.name);
    this.onSave.emit(file.name);
    this.isDropdown = false;
  }

  public searchFile(keyword: string): void {
    console.log(keyword);
  }

  public handleNewKnowledgeBase(name: string): void {
    const newName = {name: name, createdAt: new Date()};
    this.knowledgeBases.push(newName);
    this.form.get('kbControl')?.setValue(name);

    this.onSave.emit(name);
    this.isDropdown = false;
  }
}
