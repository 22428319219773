import { AfterViewInit, ChangeDetectorRef, Directive } from '@angular/core';

@Directive({
  selector: '[changeDetectionRef]'
})
export class ChangeDetectionRefDirective implements AfterViewInit {

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
