import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundNavLgComponent } from './round-nav-lg/round-nav-lg.component';
import { InlineSVGModule } from "ng-inline-svg-2";

@NgModule({
  declarations: [
    RoundNavLgComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule
  ],
  exports: [
    RoundNavLgComponent
  ]
})
export class ArrowNavigationModule { }
