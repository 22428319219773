import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'translate', pure: false })
export class TranslatePipe implements PipeTransform {
  transform(value: string): string {
    let transString = localStorage.getItem('languageObject')
    let translations: string = transString !== null ? transString : '[{}]'
    let translationsObject = JSON.parse(translations)
    if (translationsObject[value]) {
      return translationsObject[value];
    } else {
      return value;
    }
  }
}
