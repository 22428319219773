import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";
import { iconLib } from "@app/core/const/roam-icon";

export const toolbarComment: IIdNameIcon[] = [
  {
    id: 'roam-ai',
    name: 'Roam',
    icon: `${ROAM_IMAGE_SVG}/ic-roam-ai-grey.svg`
  },
  {
    id: 'mention',
    name: 'Mention',
    icon: `${ROAM_IMAGE_SVG}/ic-at.svg`
  },
  {
    id: 'emoji',
    name: 'Emoji',
    icon: `${ROAM_IMAGE_SVG}/ic-emoticon.svg`
  },
  {
    id: 'emoji',
    name: 'Emoji',
    icon: `${ROAM_IMAGE_SVG}/ic-cloud.svg`
  }
];

export const commentIconMenu: IIdNameIcon[] = [
  {
    id: 'link',
    name: 'Edit',
    icon: iconLib.editBlack.src,
  },
  {
    id: 'delete',
    name: 'Delete',
    icon: iconLib.bin.src,
  }
]
