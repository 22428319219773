import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelStatusComponent } from './label-status-icon/label-status.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LabelStatusComponent
  ],
  exports: [
    LabelStatusComponent
  ]
})
export class LabelStatusModule { }
