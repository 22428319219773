import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { IPageType } from "@app/shared/interfaces/page.interface";

@Injectable({
  providedIn: 'root'
})
export class MenuActionService {

  private menuAct: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public menuAct$: Observable<any> = this.menuAct.asObservable();
  public subMenu: BehaviorSubject<Partial<IPageType>[]> = new BehaviorSubject<Partial<IPageType>[]>([]);
  public subMenu$: Observable<Partial<IPageType>[]> = this.subMenu.asObservable();

  private slugName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly slug$: Observable<string> = this.slugName.asObservable();

  public openSubMenu: BehaviorSubject<{slug: string}> = new BehaviorSubject<{slug: string}>({ slug: status });

  constructor() { }

  set menuActive(value: number) {
    this.menuAct.next(value);
  }

  get menuActive(): number {
    return this.menuAct.value;
  }

  set slug(value: string) {
    this.slugName.next(value)
  }

  get slug(): string {
    return this.slugName.value;
  }

  set subMenuList(value:Partial<IPageType>[]) {
     this.subMenu.next(value);
  }

  get subMenuList(): Partial<IPageType>[] {
    return this.subMenu.value;
  }
}
