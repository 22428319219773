import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "app-document-email-dialog",
  templateUrl: "./document-email-dialog.component.html",
  styleUrls: ["./document-email-dialog.component.scss"],
})

// TODO: Update MatChipList

export class DocumentEmailDialogComponent implements OnInit {
  public form!: FormGroup;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
    },
    private dialogRef: MatDialogRef<DocumentEmailDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = new FormGroup({
      emails: new FormControl([], Validators.required),
      message: new FormControl(null),
    });
  }

  public addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.form
        .get("emails")
        ?.setValue([...this.form.get("emails")?.value, event.value]);
      event.chipInput!.clear();
    }
  }

  public removeKeyword(keyword: string) {
    this.form
      .get("emails")
      ?.setValue([
        ...this.form.get("emails")?.value?.filter((t: string) => t !== keyword),
      ]);
  }

  get emailList(): string[] {
    return this.form.get("emails")?.value ?? [];
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    console.log(this.form.getRawValue());
    this.closeDialog();
  }

  private closeDialog() {
    this.dialogRef.close();
  }
}
