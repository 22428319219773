import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-card-icon',
  template: `
    <div class="icon-card"
         [class.selected]="selected"
         (click)="selectCard(!selected)"
    >
      <img [src]="icon" [alt]="name">
      <label>
        {{ name }}
      </label>
    </div>
  `,
  styleUrls: ['./card-icon.component.scss']
})
export class CardIconComponent implements OnInit {

  @Input()
  public name: string = '';

  @Input()
  public icon: string = '';

  @Input()
  public selected: boolean | undefined = false;

  @Output()
  public onSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public selectCard(selected: boolean): void {
    this.onSelect.emit(selected)
  }

}
