<div *ngIf="menuList" class="roam-floating-menu"
     clickOutside (onClickOutside)="open = false">
  <div [@menuShow] *ngIf="open">
    <app-floating-menu-bar *ngFor="let menu of menuList"
                           class="menu-item"
                           [name]="menu.name"
                           [icon]="menu.icon"
                           (onClick)="openMenu(menu.id)"
    />
  </div>
  <div class="action position-relative">
    <div class="icon-menu-float"
         [class.open]="open"
         (click)="open = !open">
      <div class="icon d-flex">
        <img [src]="icon[open ? 'closeBlack' : 'magic'].src" [alt]="icon[open ? 'closeBlack' : 'magic'].alt">
      </div>
    </div>
  </div>
</div>
