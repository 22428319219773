<div class="comment-card" >
  <div class="justify-between">
    <div class="align-center gap-16">
      <app-image-view [height]="'44px'" [width]="'44px'" [rounded]="true"
                      [image]="picture"
                      [placeholderImage]="'user-circle'"
                      [placeholderWidth]="'44px'"
                      [placeholderHeight]="'44px'"
      ></app-image-view>
      <div>
        <h6 class="fw-500">
          {{ user }}
        </h6>
        <div class="font-14px tc-dust d-flex gap-8">
          <span>{{ recentTime }}</span>
          <ng-container *ngIf="isPrivate">
            <span>•</span>
            <span class="tag-label" appFontColor [status]="'private'" [isBackground]="true">
             Private
           </span>
          </ng-container>
        </div>
      </div>
    </div>
    <div>
      <span class="c-pointer" [inlineSVG]="icon.more.src" [matMenuTriggerFor]="menu"></span>
    </div>
  </div>
  <div class="mt-12 font-14px">
    {{ comment }}
  </div>
</div>

<mat-menu #menu="matMenu" class="menu-action w-150px">
  <div class="menu-action-item" *ngFor="let menu of menus">
    <span class="d-flex" [inlineSVG]="menu.icon"></span>
    <span>{{ menu.name }}</span>
  </div>
</mat-menu>
