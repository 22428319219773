import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'roam-container',
  imports: [CommonModule],
  template: `
    <div class="roam-container">
      <ng-content select="[content]"></ng-content>
    </div>
  `,
  styles: [
    `.roam-container { padding: 2.1875rem }`
  ]
})
export class RoamContainerComponent {}
