import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RoamLoaderModule } from '../roam-loader/roam-loader.module';

@Component({
  standalone: true,
  selector: 'app-roam-select-two',
  template:`
    <mat-form-field appearance="outline" class="full-width roam-field white-bg"
                    (click)="$event.preventDefault()"
    >
      <mat-label>{{ placeholder }}</mat-label>
      <mat-select [placeholder]="placeholder" #roamSelect
                  class="roam-menu"
                  [panelClass]="'select-narrow'"
                  [hideSingleSelectionIndicator]="true"
                  [(ngModel)]="selectedItem"
      >
        <mat-option *ngIf="loading">
          <div class="align-center font-14px">Loading <app-loader-dot [size]="3"></app-loader-dot></div>
        </mat-option>
        <mat-option *ngFor="let item of options" [value]="item">
          <p class="fw-500 font-14px">{{ item.name }}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ['./roam-select-two.component.scss'],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatOptionModule, RoamLoaderModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamSelectTwoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RoamSelectTwoComponent),
      multi: true,
    },
  ],
})
export class RoamSelectTwoComponent {

  @Input()
  public placeholder: string = '';

  @Input()
  public options: any[] = [];

  @Input()
  public selectedItem: any;

  @Input()
  public loading!: boolean;

  private innerValue: any;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
