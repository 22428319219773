<div style="position: absolute;left: 66px; z-index: 5;"
     [style.top]="top+'px'">
<div class="menu-container">
  <div class="pl-10">
    <header class="d-flex gap-12">
      <img class="c-pointer" [src]="icon.arrowLeftBlack.src" [alt]="icon.arrowLeftBlack.alt"
           (click)="closeButtonClick()">
      <h1>{{ title }}</h1>
    </header>
    <div class="mt-12 mb-10">
      <div class="mat-input-default">
        <input placeholder="Search"
               [(ngModel)]="keyword"
               (input)="searchAssociationList()">
        <span class="icon">
          <img [src]="icon.searchGrey.src" [alt]="icon.searchGrey.alt">
        </span>
      </div>
    </div>
  </div>
  <hr>
  <div class="menu-group roam-scrollbar">
    <div class="menu-item" *ngFor="let menu of menus"
         (click)="navigateTo(menu)"
    >
      {{ menu.title }}
    </div>
  </div>
</div>
</div>
