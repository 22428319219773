import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { MenuDropDownAnimation } from "@app/shared/components/roam-floating-menu/shared/menu-dropdown-animation.cons";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { ChatActionService } from "@app/shared/components/chat-config/shared/services/chat.service";
import { FloatingMenuBarComponent } from "./floating-menu-bar/floating-menu-bar.component";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports: [CommonModule, FloatingMenuBarComponent],
  selector: 'app-roam-floating-menu',
  templateUrl: './roam-floating-menu.component.html',
  styleUrls: ['./roam-floating-menu.component.scss'],
  animations:[ MenuDropDownAnimation ]
})
export class RoamFloatingMenuComponent implements OnInit, OnDestroy{

  @Input()
  set menus(value: IIdNameIcon[]) {
    if(value) {
      this.menuList = [this.aiMenu, ...value]
    } else {
      this.menuList = [];
    }
  }

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public open: boolean = false;
  public icon = iconLib;
  public aiMenu: IIdNameIcon = { id:'ai', name: 'Ask Roam', icon:'ai-roam'};
  public menuList!: IIdNameIcon[];

  constructor(private chatAction: ChatActionService) {}

  ngOnInit(): void {
    this.chatAction.isMenuView.next(this.menuList.length > 0)
  }

  ngOnDestroy(): void {
    this.menuList = [];
    this.chatAction.isMenuView.next(false)
  }

  public openMenu(type: string): void {
    if(type === 'ai' && this.menuList) {
      this.chatAction.openAssistance.next(true);
      this.open = false;
    } else {
      this.onClick.emit(type)
    }
  }
}
