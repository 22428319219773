import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SearchInputComponent } from "../search-input/search-input.component";
import { ButtonFilterComponent } from "../button-filter/button-filter.component";

@Component({
  standalone: true,
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  imports: [CommonModule, SearchInputComponent, ButtonFilterComponent],
  styles:[`
    .search-filter {
      padding: 1rem 0;
      display: flex;
      gap: 10px;
    }

    @media screen and (max-width: 1180px) {
      .search-filter {
        width: unset;
      }
    }

    .search-filter  app-search-input {
      min-width: 270x;
    }
  `]
})
export class SearchFilterComponent {

  @Input()
  public isClear!: boolean;

  @Output()
  public search: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public onFilter: EventEmitter<boolean> = new EventEmitter<boolean>(true);
}
