import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appLazyImage]'
})
export class LazyImageDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const imgElement = entry.target as HTMLImageElement;
          const src = imgElement.getAttribute('data-src');
          if (src) {
            imgElement.src = src;
            imgElement.onload = () => {
              this.renderer.addClass(imgElement, 'loaded');
            };
          }
          observer.unobserve(imgElement);
        }
      });
    });

    observer.observe(this.el.nativeElement);
  }

}
