<div mat-dialog-title>
  <p class="fw-600 title">AI Data Assistant - {{ data.element.name }} </p>
  <img
    src="/assets/svg/close-icon.svg"
    alt="Close Icon"
    class="c-pointer"
    mat-dialog-close
  />
</div>
<div >
  <mat-dialog-content class="document-query-dialog-content">
    <form [formGroup]="form">
      <div class="w-100 mb-20px">
        <div class="ai-input">
          <span class="icon" (click)="showAskRoam()">
            <img [src]="icon.roamAi.src" [alt]="icon.roamAi.alt">
          </span>
          <div class="ask-info" *ngIf="isAskRoamInfo">
            <h6>Ask Roam for answers in moments</h6>
            <div class="ask-info-close c-pointer" (click)="showAskRoam()">x</div>
            <div class="desc">
              Seek the specific information you need from any pdf file without asking your team or having to read through hard to understand documents
            </div>
          </div>
          <input type="text" class="ai-input-control"
                 formControlName="message"
                 (keyup.enter)="onSubmit()"
                 (input)="hideAskInfo()"
          >
          <span class="icon c-pointer"
                [class.move-up-down]="isProcessing"
                (click)="onSubmit()">
            <span class="btn"
                  [class.item-icon]="form.get('message')?.value"
                  [inlineSVG]="icon.btnUpChange.src"></span>
          </span>
        </div>

        <div class="text-render" *ngIf="responseMessage">
          <div class="text">
            <div [innerHTML]="formatResponseMessage(responseMessage)"></div>
          </div>
          <div class="action">
            <div class="refresh justify-center c-pointer" (click)="onSubmit()">
              <img [src]="icon.refresh.src" [alt]="icon.refresh.alt">
              <span>Try Again</span>
            </div>
            <button mat-button>Copy</button>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

</div>
<!--<mat-dialog-actions class="document-query-dialog-actions">-->
<!--  <button mat-button class="close-btn" mat-dialog-close>Cancel</button>-->
<!--  <button mat-button class="continue-btn" (click)="onSubmit()">Ask Document</button>-->
<!--</mat-dialog-actions>-->
