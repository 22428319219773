import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'doc-icon',
  standalone: true,
  imports: [CommonModule, RoamIconComponent ],
  template:`
    <div class="align-center gap-8" (click)="onClick.emit()">
      <roam-icon [name]="icon" [color]="config.color" [size]="config.size" ></roam-icon>
      <h6 class="tc-black fw-500 set-file-name" [title]="label">
        {{ label }}
      </h6>
    </div>
  `,
  styles:[
    `
      .set-file-name {
        width: calc(80% - 16px); /* Adjust 16px for gap-8 */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500 !important;
        line-height: 1.7;
      }
    `
  ]
})
export class DocIconComponent {

  @Input()
  public label!: string;

  @Input()
  public icon: string = 'folder';

  @Input()
  public config!: {size: string, color: string};

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
