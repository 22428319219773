import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-label-list',
  template:`
    <div class="text-label-list pb-18">
      <div class="align-center gap-10">
        <div class="counter">
          {{ counter }}
        </div>
        <h6 class="font-16px fw-500">
          {{ title }}
        </h6>
      </div>
      <div class="font-14px pt-12">
        {{ text }}
      </div>
    </div>
  `,
  styles:[`
    .counter {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 27px;
      background: #876486;
      border-radius: 99px;
      color: #ffffff;
      font-weight: 600;
    }
  `]
})
export class TextLabelListComponent {

  @Input()
  public counter!: number;

  @Input()
  public title!: string;

  @Input()
  public text!: string;
}
