import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { IPageType } from "@app/shared/interfaces/page.interface";
import { MenuBackNavigationComponent } from "@app/pages/main/shared/components/menu-back-navigation/menu-back-navigation.component";

@Component({
  selector: 'app-roam-sidebar-submenu',
  standalone: true,
  imports: [CommonModule, RouterModule, MenuBackNavigationComponent],
  template:`
    <div>
      <div class="menu-container">
        <menu-back-navigation [pageName]="pageName"/>
        <div class="menu-group roam-scrollbar mt-8">
          <div class="submenu-item" *ngFor="let menu of subMenus"
               (click)="onNavigate.emit(menu?.type)">
            {{ menu?.title }}
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./roam-sidebar-submenu.component.scss']
})
export class RoamSidebarSubmenuComponent {

  @Input()
  public pageName!: string;

  @Input()
  public slug!: string;

  @Input()
  public subMenus: Partial<IPageType[]> = [];

  @Output()
  public onNavigate: EventEmitter<string> = new EventEmitter<string>()

}
