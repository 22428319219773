<div class="left-side-bar__wrapper">
    <div class="left-side-bar__header">
        <div class="justify-between">
          <p class="details-header">{{ properties.length }} Listings</p>
          <mat-icon *ngIf="!isReservationDetails && !isCalendarDetails" class="add-icon">add</mat-icon>
        </div>
        <mat-form-field appearance="outline" class="full-width roam-field">
          <button matPrefix mat-button class="search-icon">
            <mat-icon>search</mat-icon>
          </button>
          <input
            type="text"
            class="roam-text"
            matInput
            placeholder="{{ 'GEN_SEARCH' | translate }}"
            name="search"
            [(ngModel)]="search"
          />
        </mat-form-field>
      </div>
      <div class="justify-between" *ngFor="let property of properties; let first = first">
        <mat-accordion class="example-headers-align" [class.border-top]="first">
          <mat-expansion-panel [expanded]="true" (opened)="setProperty(property.id)" (closed)="removeProperty(property.id)">
            <mat-expansion-panel-header [class.mat-calendar-header]="isCalendarDetails">
              <mat-panel-title class="details-title">{{ property.name }}</mat-panel-title>
              <div *ngIf="isCalendarDetails" class="occupation-info roam-text">2/1</div>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let item of property.units">
              <div class="mat-accordion-item" [class.calendar-item]="isCalendarDetails">
                <div class="align-center gap-19 mat-accordion-row">
                  <img [src]="item.images[0].url" alt="unit-image">
                  <div>
                      <p class="roam-title">{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
</div>
