import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: 'app-roam-card-select',
  imports: [InlineSVGModule],
  template:`
    <div class="roam-card-select"
         [class.small]="small"
         [class.active]="active"
         (click)="onClick.emit()"
    >
      <div class="d-flex icon" [inlineSVG]="icon"></div>
      <label class="c-pointer">{{ label }}</label>
    </div>
  `,
  styles:[`
    .roam-card-select {
      cursor: pointer;
      padding: 1.45rem;
      border-radius: 9px;
      border: 1px solid rgba(143, 134, 147, 0.30);
      background-color: #ffffff;
      font-weight: 500;
    }

    .roam-card-select.small {
      padding: 12px
    }

    .roam-card-select .icon {
      width: 2rem;
      height: 2rem;
      margin-bottom: 8px;
    }

    .roam-card-select:hover {
      background: rgba(145, 70, 106, 0.05);
    }

    .roam-card-select.active {
      background: rgba(145, 70, 106, 0.05);
      border: 1.5px solid #91466A;
    }
  `]
})
export class RoamCardSelectComponent {

  @Input()
  public icon!: string;

  @Input()
  public label!: string;

  @Input()
  public active!: boolean;

  @Input()
  public small!: boolean;

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public selectCard(): void {

  }
}
