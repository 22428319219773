import { TextCellTableComponent } from "../components/text-cell-table/text-cell-table.component";
import { BaseCellTableComponent } from "../components/base-cell-table.component";
import { SourceTableColumnType } from "../cell-table.model";
import { DateCellTableComponent } from "../components/date-cell-table/date-cell-table.component";
import { ITableColumn } from "@app/shared/interfaces/table.interface";

export function getCellComponent(
  type: SourceTableColumnType,
  col?: ITableColumn,
): typeof BaseCellTableComponent {
  if (col?.name === "date") return DateCellTableComponent;
  return {
    text: TextCellTableComponent,
    date: DateCellTableComponent,
  }[type];
}
