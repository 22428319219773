<div class="visible__menu">
  <div class="visible__menu-container">
    <div *ngIf="isEmpty" style="padding-top: 50px">
      <img src="/assets/svg/ic-file-searching.svg" alt="Folder Icon" />
      <p>Select a file or folder to view details.</p>
    </div>

    <mat-tab-group *ngIf="!isEmpty" mat-align-tabs="center" class="c-mat-group">
      <mat-tab label="Sharing">
        <div>
          <p class="roam-title">Collaborators</p>

          <div class="my-12px">
            <p class="roam-description pb-10px" *ngFor="let item of [0, 1, 2]">
              Bruno Fernandes
              <span class="d-block grey-text">bruno&#64;gmail.com</span>
            </p>
          </div>

          <a class="roam-title text-underline c-pointer" style="color: #91466a">
            Invite People
          </a>
        </div>
        <div class="mt-20px">
          <p class="roam-title">Shared Link</p>

          <a class="roam-title text-underline c-pointer" style="color: #91466a">
            Create Link
          </a>
        </div>
      </mat-tab>
      <mat-tab label="Details">Content Details</mat-tab>
    </mat-tab-group>
  </div>
</div>
