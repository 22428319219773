<ng-container *ngIf="!!fileSelected.length">
  <img
    src="/assets/svg/close-icon.svg"
    alt="Close Icon"
    class="v-align-middle c-pointer"
    style="width: 24px; margin-right: 5px;margin-top: -1px;height: 1.3rem;"
    (click)="reset()"
  />
  <span *ngIf="fileSelected.length === 1">
    {{ fileSelected[0].name }} <span class="tc-dust">is Selected</span>
  </span>
  <span *ngIf="fileSelected.length > 1" class="tc-dust">
    {{ fileSelected.length }} items selected
  </span>

</ng-container>
