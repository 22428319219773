import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { FilterContentComponent } from "@app/shared/components/filter-dropdown/filter-content/filter-content.component";

@Component({
  selector: 'filter-status',
  standalone: true,
  imports: [
    CommonModule, MatCheckboxModule, MatIconModule, FilterContentComponent
  ],
  template:`
    <filter-content (search)="applyFilter()"
                    (clear)="clearFilter()">
      <div class="filter-status align-center f-column gap-10">

        <div *ngFor="let status of statusList;let status_i = index"
             class="round-checkbox reverse sm">
          <mat-checkbox (change)="updateStatus($event, status_i)"
                        [value]="status.id"
                        [checked]="status.checked"
          >
            <span class="fw-500">{{ status.name }}</span>
          </mat-checkbox>
        </div>
      </div>
    </filter-content>
  `,
  styles:[
      `.filter-status {
      width: 300px;
    }`
  ]
})
export class FilterStatusComponent {

  @Output()
  public filterValue: EventEmitter<string[]> = new EventEmitter<string[]>();

  public statusList: {id: string, name: string, checked: boolean}[] = [
    {
      id: 'new',
      name: 'New',
      checked: false
    },
    {
      id: 'waiting',
      name: 'Waiting',
      checked: false
    },
    {
      id: 'completed',
      name: 'Completed',
      checked: false
    },
    {
      id: 'assigned',
      name: 'Assigned',
      checked: false
    },
    {
      id: 'scheduled',
      name: 'Scheduled',
      checked: false
    },
    {
      id: 'cancel',
      name: 'Cancel',
      checked: false
    }
  ]

  public status: any[] = []

  public updateStatus(selectedOption: MatCheckboxChange, index: number): void {
    this.statusList[index].checked = selectedOption.checked;
    if (selectedOption.checked) {
      this.status.push(selectedOption.source.value);
    } else {
      const selectedIndex = this.status.findIndex(_status => _status === selectedOption.source.value);
      this.status.splice(selectedIndex, 1);
    }

    console.log(this.status);
  }

  public applyFilter(): void {
    this.filterValue.emit(this.status)
  }

  public clearFilter(): void {
    this.status = [];
    this.statusList.forEach(_status => _status.checked = false);
    this.filterValue.emit([])
  }
}
