import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { ChipAvatarComponent } from './chip-avatar/chip-avatar.component';
import { RoamLayoutModule } from "@app/shared/components/roam-layout/roam-layout.module";
import { InlineSVGModule } from "ng-inline-svg-2";

@NgModule({
  declarations: [
    ChipComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    RoamLayoutModule,
    ChipAvatarComponent
  ],
  exports: [
    ChipComponent,
    ChipAvatarComponent
  ]
})
export class ChipModule { }
