// absolute.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'uncamel'
})
export class UncamelPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (typeof value !== 'string') {
      return '-';
    }

    // Split the camel-cased string into words
    const words = value.replace(/([a-z])([A-Z])/g, '$1 $2').split(/\s+/);

    // Capitalize each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words with spaces
    return capitalizedWords.join(' ');
  }
}
