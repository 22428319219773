import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddDecisionStampComponent } from "@app/shared/components/roam-detail-component/add-decision-stamp/add-decision-stamp.component";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AddDecisionStampComponent
  ],
  exports: [
    AddDecisionStampComponent
  ]
})
export class RoamDetailComponentModule { }
