import { Component, EventEmitter, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

export const SAMPLE_COMMENTS = [
  {
    id: 'a',
    user: 'Marcus Rashford',
    time: '12 minutes ago',
    comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    photo: './assets/sample/sample-ava-01.svg',
    isPrivate: false
  },
  {
    id: 'b',
    user: 'Bruno Fernandes',
    time: '12 minutes ago',
    comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    photo: './assets/sample/sample-ava-2.svg',
    isPrivate: true
  }
]
@Component({
  selector: 'app-document-preview-comment',
  templateUrl: './document-preview-comment.component.html',
  styleUrls: ['./document-preview-comment.component.scss']
})
export class DocumentPreviewCommentComponent {

  @Output()
  public onMenuCollapse: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;

  // SAMPLE
  public comments = SAMPLE_COMMENTS;
}
