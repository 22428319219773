import { Component, Inject } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { InputGroupComponent } from "@app/shared/components/input/input-group/input-group.component";
import { ButtonRadiusComponent } from "@app/shared/components/button/button-radius/button-radius.component";

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [
    CommonModule, MatDialogModule, RoamIconComponent,
    InputGroupComponent, ButtonRadiusComponent],
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent {
  public title!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string}
  ) {
    this.title = data['title'];
  }
}
