import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-chip',
  template:`
    <div class="chip"
         [class.active]="isSelected"
         (click)="selectTag()"
    >
      <span class="tag justify-center">
        <img [src]="isSelected ? icon.tagWhite.src: icon.tag.src" [alt]="icon.tag.alt">
      </span>
      <span class="text">
        {{ name }}
      </span>
      <span *ngIf="isSelected" (click)="delete($event)">
        <img class="close" [src]="icon.closeWhite.src" [alt]="icon.closeWhite.alt">
      </span>
    </div>
  `,
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input()
  public name: string = '';

  @Input()
  public isSelected: boolean = false;

  @Output()
  public onSelected: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  public onDelete: EventEmitter<boolean> = new EventEmitter<boolean>(false)

  public icon = iconLib;

  constructor() { }

  ngOnInit(): void {
  }

  public selectTag(): void {
    this.onSelected.emit(!this.isSelected)
  }

  public delete($event: MouseEvent): void {
    $event.stopPropagation();
    this.onDelete.emit(true)
  }

}
