import { Component, Input, OnInit, ViewChild, forwardRef } from "@angular/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { ITime } from "@app/shared/interfaces/time.interface";
import { iconLib } from "@app/core/const/roam-icon";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports:[CommonModule, MatOptionModule, InlineSVGModule, MatFormFieldModule, MatSelectModule],
  selector: 'app-roam-time',
  templateUrl: './roam-time.component.html',
  styleUrls: ['./roam-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamTimeComponent),
      multi: true
    }
  ]
})
export class RoamTimeComponent implements OnInit, ControlValueAccessor{

  @Input()
  public placeholder!: string;

  @ViewChild(MatSelect) roamSelect!: MatSelect;

  public times: ITime[] = [];
  public icon = iconLib;
  currentValue!: Date;
  onChange: any = () => { }
  onTouch: any = () => { }

  set value(val: any) {
    this.currentValue = val;
    this.onChange(val)
    this.onTouch(val)
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onValueChange (event: Event) {
    // this.value = event.value;
  }

  ngOnInit(): void {
   this.times = this.createTimeArray();
  }

  public createTimeArray(): ITime[] {
    const times: ITime[] = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const period = hour < 12 ? 'AM' : 'PM';
        const formattedHour = (hour % 12 || 12);
        const formattedMinute = minute;
        const formattedSecond = 0;

        times.push({
          hours: formattedHour,
          minutes: formattedMinute,
          seconds: formattedSecond,
          view: `${formattedHour.toString().padStart(2, '0')}:${formattedMinute.toString().padStart(2, '0')} ${period}`,
        });
      }
    }

    return times;
  }

  closeSelect() {
    this.roamSelect.close();
  }
}
