import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Column } from "@app/shared/components/roam-table-type/interface/column.interface";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { textStatus } from "@app/pages/contacts/shared/sample-table-contact";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";

@Component({
  standalone: true,
  selector: 'app-roam-table-type',
  templateUrl: './roam-table-type.component.html',
  styleUrls: ['./roam-table-type.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
  ]
})
export class RoamTableTypeComponent<T> implements OnInit {

  @Input()
  public tableColumns: Array<Column> = [];

  @Input()
  public tableData: Array<any> = [];

  @Output()
  public onNavigateDetail: EventEmitter<string | number> = new EventEmitter<string|number>()

  @Output()
  public onSelectedRow: EventEmitter<boolean> = new EventEmitter<boolean>(false)

  public displayColumns: Array<string> = [];
  public dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();

  public statusConfig = textStatus;

  constructor() { }

  ngOnInit(): void {
    this.displayColumns = this.tableColumns.map(c => c.columnDef);
    this.dataSource = new MatTableDataSource(this.tableData)
  }

  get isSelectedAll(): boolean {
    return this.tableData.every(element => element.isSelected)
  }

  public navigateDetail(id: number | string): void {
    this.onNavigateDetail.emit(id);
  }

  public selectAllData(isChecked: boolean): void {
    this.tableData.forEach(element => {
      element.isSelected = isChecked
    });
    this.onSelectedRow.emit(isChecked)
  }

  public selectedRow(): void {
    const isSelected = this.tableData.some(element => element.isSelected);
    this.onSelectedRow.emit(isSelected)
  }

}
