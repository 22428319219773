import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  imports: [ImageViewComponent, InlineSVGModule],
  selector: 'app-chip-avatar',
  template:`
    <div class="chip-avatar"
         [class.small]="small"
    >
      <div class="align-center gap-10">
        <app-image-view [width]="small ? '28px' : '40px'" [height]="small? '28px': '40px'"
                        [image]="photo"
                        [rounded]="true"
        ></app-image-view>

        <span class="tc-black fw-500">
      {{ name }}
    </span>
      </div>
      <div>
    <span class="close c-pointer" [inlineSVG]="icon.closeIh.src"
          (click)="onClose.emit()"
    ></span>
      </div>
    </div>
  `,
  styles: [
    `
      .chip-avatar {
        display: inline-flex;
        align-items: center;
        line-height: 12px;
        border-radius: 99px;
        background: #F3F3F3;
        padding: 8px 12px;
        gap: 12px;
      }

      .chip-avatar.small {
        height: 44px;
      }

      .close {
        fill: #949494;
      }

    `
  ]
})
export class ChipAvatarComponent {

  @Input()
  public photo: string = './assets/sample/avatar.png';

  @Input()
  public name: string = 'Jhon doe';

  @Input()
  public small: boolean = false;

  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;

}
