<div class="recent-activity d-flex gap-16">
  <div>
    <app-image-view [image]="sampleImage"
                    [width]="'68px'"
                    [height]="'68px'"
                    [borderRadius]="8"></app-image-view>
  </div>
  <div class="info d-flex f-column">
    <span class="font-14px tc-dust" >Event <span>•</span> August 30, 2023</span>
    <label>Lorem ipsum is dummy text</label>
    <span class="font-14px">Marcus Rashford</span>
  </div>
</div>
