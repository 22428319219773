import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { CommonModule } from "@angular/common";
import { IconRoundComponent } from "@app/shared/components/roam-layout/icon-round/icon-round.component";

@Component({
  standalone: true,
  imports:[CommonModule, IconRoundComponent],
  selector: 'app-document-mobile-action',
  template: `
    <div class="d-flex gap-10 justify-end mobile-view flex">
      <app-icon-round [src]="isLayout ? icon.list.src : icon.layout.src " [alt]="'layout'"
                      [class]="'std'" (onClick)="switchLayout()">
      </app-icon-round>
      <button class="btn-o-primary fw-500 pl-24 pr-24 std"
              [disabled]="isDisable"
              (click)="onSelectFile.emit()">Select</button>
      <ng-content select="[item]"></ng-content>
    </div>
  `,
  styleUrls: ['./document-mobile-action.component.scss']
})
export class DocumentMobileActionComponent {

  @Input()
  public showCheckbox: boolean = true;

  @Input()
  public isLayout: boolean = false;

  @Input()
  public allSelected: boolean = false;

  @Input()
  public isDisable: boolean = false;

  @Output()
  public onSwitchLayout: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public onSelectFile: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;


  public switchLayout(): void {
    this.onSwitchLayout.emit();
  }
}
