import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonRadiusComponent } from "@app/shared/components/button/button-radius/button-radius.component";
import { IIdName, IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { MatDialog } from "@angular/material/dialog";
import { MaintenanceAddEmployeeComponent } from "@app/pages/maintenance/shared/dialog/maintenance-add-employee/maintenance-add-employee.component";
import { ShareDialogComponent } from "@app/shared/components/share-action/share-dialog/share-dialog.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { SharedLinkDialogComponent } from "@app/shared/components/share-action/shared-link-dialog/shared-link-dialog.component";

@Component({
  selector: 'share-action',
  standalone: true,
  imports: [CommonModule, ButtonRadiusComponent],
  template:`
    <div *ngFor="let action of shareActions"
         (click)="openDialog(action.id)">
        <button-radius [name]="action.id"/>
    </div>
  `,
  styles:[
    `
      :host {
        display: flex;
        gap: 1rem;
      }
    `
  ]

})
export class ShareActionComponent {
  @Input()
  public module!: string;

  @Input()
  public id: any;

  public shareActions: IIdName[] = [
    {
      id:'share',
      name: 'Share'
    },
    {
      id:'link',
      name: 'Link'
    },
    {
      id:'bin',
      name: 'Trash'
    }
  ];

  constructor(private dialog: MatDialog) {
  }

  public openDialog(type: string): void {
    switch (type) {
      case 'share':
        this.share();
        break;
      case 'link':
        this.link();
        break;
      case 'bin':
        this.delete();
        break;
      default:
    }
  }

  private share(): void {
    this.dialog.open(ShareDialogComponent, {
      ...dialogConfig.default,
      data: {title: this.module}
    })
  }

  private link(): void {
    this.dialog.open(SharedLinkDialogComponent, {
      ...dialogConfig.default,
      autoFocus: false,
      data: {title: this.module}
    })
  }

  private delete(): void {
    const confirm: IConfirmationDialog = {
      title: 'Delete Item',
      body: 'Are you sure wan to delete this item?',
      btnCancel: 'Cancel',
      btnSubmit: 'Okay'
    }

    this.dialog.open(ConfirmationDialogComponent, {
      ...dialogConfig.default,
      data: {
        confirmation: confirm
      }
    })
  }
}
