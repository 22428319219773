<div class="set-position">
  <ul class="share-action">
    <li>
      <img [src]="icon.download.src" [alt]="icon.download.alt"
           width="18px"
      >
      <span>Download</span>
    </li>
    <li>
      <img [src]="icon.bin.src" [alt]="icon.bin.alt">
      <span>Delete</span>
    </li>
    <li>
      <img [src]="icon.copy.src" [alt]="icon.copy.alt">
      <span>Move or Copy</span>
    </li>
  </ul>
</div>
