<div class="position-relative" *ngIf="documents.length > 0">
  <swiper #swiper [config]="swiperConfig">
    <ng-template swiperSlide *ngFor="let doc of documents" class="swiper-card">
      <!-- FILE -->

      <file-view *ngIf="doc.documentFolderType === 'document'"
                 [id]="doc.id"
                 [name]="doc.name"
                 [date]="doc.updatedAt"
                 [url]="doc.url"/>

      <!-- DOCUMENT -->
      <app-doc-card *ngIf="doc.documentFolderType === 'folder'"
                    [noCheckbox]="true"
                    [title]="doc.name"
                    [date]="doc.updatedAt">
        <ng-container action>
          <div class="align-center gap-12 ptb-2 mn-12">
            <button-favorite></button-favorite>
            <roam-icon [name]="'more-horizontal'"></roam-icon>
          </div>
        </ng-container>
      </app-doc-card>
    </ng-template>
  </swiper>

  <button
    class="btn-swiper-nav"
    (click)="slidePrev()"
    [ngStyle]="{ visibility: isSlideBeginning ? 'hidden' : 'visible' }"
    [disabled]="isSlideBeginning"
  >
    <roam-icon name="chevron-left" size="16"/>
  </button>

  <button
    class="btn-swiper-nav next deg-180"
    (click)="slideNext()"
    [ngStyle]="{  visibility: isSlideEnd ? 'hidden' : 'visible' }"
    [disabled]="isSlideEnd"
  >
    <roam-icon name="chevron-left" size="16"/>
  </button>
</div>

<div class="tc-dust font-14px" *ngIf="documents.length === 0"> No Files Added</div>
