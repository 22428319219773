import { Component, Input } from "@angular/core";
import { iconLib, iconSocmed } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-post-section',
  templateUrl: './post-section.component.html',
  styleUrls: ['./post-section.component.scss']
})
export class PostSectionComponent {

  @Input()
  public image: string = '';

  @Input()
  public name: string = '';

  @Input()
  public timeStamp: string | number  = '';

  @Input()
  public text: string = '';

  @Input()
  public countLiked: number = 0;

  @Input()
  public countShared: number = 0;

  @Input()
  public countComment: number = 0;

  @Input()
  public liked: boolean = false;

  public icon = { ...iconLib, ...iconSocmed };

  get textEllipsis(): boolean {
    return this.text.length > 160;
  }

  public seeMore(event: Event): void{
    const parentEl = (<HTMLElement>event.target).parentElement!;
    parentEl.style.display = 'none';
    (<HTMLElement>parentEl.nextElementSibling).style.display = 'block';
  }
}
