import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'file-view-action',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  templateUrl: './file-view-action.component.html',
  styles:[
    `
      .file-view-action {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        border: 1px solid #E8E4E9;
        padding: 8px 12px;
        border-radius: 8px;
      }
    `
  ]
})
export class FileViewActionComponent {

}
