import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports:[
    CommonModule,
    MatDialogModule
  ],
  selector: 'app-roam-dialog-mdc',
  templateUrl: './roam-dialog-mdc.component.html',
  styleUrls: ['./roam-dialog-mdc.component.scss']
})
export class RoamDialogMdcComponent {

  @Input()
  public title!: string;
}
