import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { iconLib } from "@app/core/const/roam-icon";
import { GptService } from '@app/shared/services/gpt.service'

@Component({
  selector: "app-document-query-dialog",
  templateUrl: "./document-query-dialog.component.html",
  styleUrls: ["./document-query-dialog.component.scss"],
})
export class DocumentQueryDialogComponent implements OnInit {
  public form!: FormGroup;
  public responseMessage: string | null = null;
  public icon = iconLib;
  public isProcessing: boolean = false;
  public isAskRoamInfo: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      element: any;
    },
    private dialogRef: MatDialogRef<DocumentQueryDialogComponent>,
    private gptService: GptService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.isAskRoamInfo = true;
  }
  public hideAskInfo(): void {
    this.isAskRoamInfo = false;
  }

  private initForm(): void {
    this.form = new FormGroup({
      emails: new FormControl([], Validators.required),
      message: new FormControl(null),
    });
  }
  formatResponseMessage(message: string): string {
    return message.replace(/\n/g, "<br>");
  }

  public addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.form
        .get("emails")
        ?.setValue([...this.form.get("emails")?.value, event.value]);
      event.chipInput!.clear();
    }
  }

  public removeKeyword(keyword: string) {
    this.form
      .get("emails")
      ?.setValue([
        ...this.form.get("emails")?.value?.filter((t: string) => t !== keyword),
      ]);
  }

  get emailList(): string[] {
    return this.form.get("emails")?.value ?? [];
  }

  public onSubmit(): void {
    this.isProcessing = true;
    const result = this.gptService.queryDocument(
      'gpt',
      this.data.element.url,
      this.form.getRawValue().message
    )
    result.subscribe(
      (response) => {
        console.log(response.output);
        this.responseMessage = response.output;
        this.isProcessing = false;
      },
      (error) => {
        console.log(error);
        this.responseMessage = error.responseMessage;
        this.isProcessing = false;
      }
    )
  }

  public showAskRoam(): void {
    this.isAskRoamInfo = false;
  }
}
