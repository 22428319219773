import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";

@Component({
  standalone: true,
  imports:[CommonModule, MatTabsModule],
  selector: "app-documents-quick-action",
  templateUrl: "./documents-quick-action.component.html",
  styleUrls: ["./documents-quick-action.component.scss"],
})
export class DocumentsQuickActionComponent {
  @Input()
  public isEmpty: boolean = true;

  constructor() {}
}
