<div class="menu-bar" (click)="onClick.emit()">
  <div class="align-center gap-8">
    <div class="icon">
      <roam-icon [name]="icon ? icon : 'pencil-box'" color="white" size="16"/>
    </div>
    <label class="font-14px fw-500">
      {{ name }}
    </label>
  </div>
</div>
