export enum DocumentType {
  FILE = 'file',
  FOLDER = 'folder'
}

export enum DocAction {
  LINK = 'link-hover',
  SHARE = 'share',
  AI = 'ai-roam'
}
