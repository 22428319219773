import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { IShortCut } from '@app/core/components/utils/data';
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";

@Component({
  standalone: true,
  imports:[CommonModule, MatCardModule],
  selector: "app-shortcut-menu-card",
  templateUrl: "./shortcut-menu-card.component.html",
  styleUrls: ["./shortcut-menu-card.component.scss"],
})
export class ShortcutMenuCardComponent implements OnChanges, OnInit {
  @Input() public shortcut!: IShortCut;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["shortcut"].currentValue) {
      this.shortcut.focus = changes["shortcut"].currentValue;
    }
  }

  ngOnInit(): void {}
}
