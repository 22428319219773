import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { Observable, Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from "@angular/common";

@Directive({
  selector: '[roamClick]'
})
export class RoamClickDirective implements OnInit, OnDestroy{

  @Input()
  public clickListenerDisabled = false;

  @Output('roamClick')
  public click = new EventEmitter<KeyboardEvent>();

  private destroy$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private elm: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit(): void {
    if(!this.clickListenerDisabled) {
      this.listen()
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: any) => {
          event.stopPropagation();
          this.click.emit(event)
        })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  private listen(): Observable<any> {
    return new Observable<MouseEvent>((observer) => {
      return this.renderer.listen(this.elm.nativeElement, 'click', (event) => {
        observer.next(event)
      })
    })
  }
}
