import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
} from "@angular/core";
import { getCellComponent } from "./tools/getCellComponent.tool";
import { ComponentLoaderFeature } from "@app/shared/features/load-component.feature";
import { BaseCellTableComponent } from "./components/base-cell-table.component";
import { SourceTableColumnType } from "./cell-table.model";
import { ITableColumn } from "@app/shared/interfaces/table.interface";

@Component({
  selector: "app-cell-table",
  templateUrl: "./cell-table.component.html",
  styleUrls: ["./cell-table.component.scss"],
})
export class CellTableComponent {
  @Input() content: any;
  @Input() type?: SourceTableColumnType;
  @Input() cell: any;
  @Input() col?: ITableColumn;

  @Output("onSubmit") onCellSubmit = new EventEmitter();

  private instanceChildComponent?: BaseCellTableComponent;

  constructor(
    public ref: ViewContainerRef,
    private loader: ComponentLoaderFeature,
  ) {}

  refresh(): void {
    this.instanceChildComponent?.refresh();
  }

  ngOnInit() {
    this.load(this.type, this.content, this.col);
  }

  load(type: any, content: any, col?: ITableColumn) {
    const cellComponent = getCellComponent(type, col);
    this.instanceChildComponent = this.loader.loadComponent(
      cellComponent,
      this.ref,
      [
        { id: "content", value: content },
        { id: "cell", value: this.cell },
      ],
      [{ id: "onSubmit", emitter: this.onCellSubmit }],
      "after",
    );
  }
}
