import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FileViewComponent } from "@app/shared/components/file-config/file-view/file-view.component";
import { SwiperComponent, SwiperModule } from "swiper/angular";
import { SwiperOptions } from "swiper";
import { fileFavoriteConfig } from "@app/pages/associations/shared/const/association-swiper.const";
import { ButtonAddUnderlineComponent } from "@app/shared/components/button/button-add-underline/button-add-underline.component";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { DocCardComponent } from "@app/shared/components/document-collection/doc-card/doc-card.component";
import { ButtonFavoriteComponent } from "@app/shared/components/button/button-favorite/button-favorite.component";

@Component({
  selector: 'file-folder-list',
  standalone: true,
  imports: [
    CommonModule, FileViewComponent, SwiperModule,
    ButtonAddUnderlineComponent, RoamIconComponent,
    DocCardComponent, ButtonFavoriteComponent
  ],
  templateUrl: './file-folder-list.component.html'
})
export class FileFolderListComponent {

  @Input()
  public documents: any[] = [];


  @ViewChild('swiper', { static: false })
  public swiper?: SwiperComponent;

  public swiperConfig: SwiperOptions = fileFavoriteConfig;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  get isSlideBeginning(): boolean {
    return this.swiper?.swiperRef.isBeginning || false;
  }

  get isSlideEnd(): boolean {
    return this.swiper?.swiperRef.isEnd || false;
  }

  slideNext(){
    this.swiper?.swiperRef.slideNext(100);
  }

  slidePrev(){
    this.swiper?.swiperRef.slidePrev(100);
  }
}
