import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "humanReadableTime",
})
export class HumanReadableTimePipe implements PipeTransform {
  transform(totalSeconds: number): string {
    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return "Invalid input";
    }

    const hours: number = Math.floor(totalSeconds / 3600);
    const minutes: number = Math.floor((totalSeconds % 3600) / 60);

    const hoursDisplay = hours > 0 ? `${hours}h ` : "";
    const minutesDisplay = minutes > 0 ? `${minutes}m` : "";

    // If both hours and minutes are 0, we'll display 0m
    if (hours === 0 && minutes === 0) {
      return "0m";
    }

    return `${hoursDisplay}${minutesDisplay}`;
  }
}
