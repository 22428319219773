import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension',
  standalone: true
})
export class FileExtensionPipe implements PipeTransform {

  transform(filename: string): string {
    if (!filename) return '';

    const parts = filename.split('.');
    if (parts.length === 1) return '';
    return parts[parts.length - 1];
  }

}
