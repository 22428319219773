<div class="checkbox-select-list">
  <mat-checkbox [(ngModel)]="checked"
                (change)="onChange.emit()"
  >
    <div class="align-center gap-16">
      <app-image-view [image]="''"
                      [height]="'44px'" [width]="'44px'" [rounded]="true"
                      [placeholderImage]="'user'"
                      [placeholderHeight]="'44px'"
      ></app-image-view>
      <div>
        <app-text-label-desc [label]="'Luke Show'"
                             [desc]="'marcus@gmail.com'"
                             [classLabel]="'tc-black'"
                             [class]="'ln-normal'"
        ></app-text-label-desc>
      </div>
    </div>
  </mat-checkbox>
</div>
