import { Component } from '@angular/core';

@Component({
  selector: 'app-card-recent',
  templateUrl: './card-recent.component.html',
  styles: [
    `.recent-activity {
      direction: ltr;
    }
    `
  ]
})
export class CardRecentComponent {

  public sampleImage = '/assets/sample/home.png';
}
