import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'button-favorite',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <div class="icon-favorite">
      <roam-icon name="favorite" [color]="color" size="24"></roam-icon>
    </div>
  `,
  styles:[
    `
      ::ng-deep .icon-favorite > * {
        fill: none;
        stroke: #222222;
        stroke-width: 1.5;
      }

      ::ng-deep .icon-favorite.star > *{
        fill: #876486;
        stroke: #876486;
      }

      ::ng-deep .icon-favorite:hover > * {
        fill: #91466A;
        stroke: #91466a;
      }
    `
  ]
})
export class ButtonFavoriteComponent {
  public color: string = '';
}
