import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterStatusComponent } from "@app/shared/components/filter-dropdown/filter-status/filter-status.component";
import { FilterContainerComponent } from "@app/shared/components/filter-container/filter-container.component";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FilterStatusComponent,
    FilterContainerComponent
  ],
  exports: [
    FilterStatusComponent
  ]
})
export class FilterDropdownModule { }
