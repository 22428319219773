import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocCardComponent } from './doc-card/doc-card.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { EllipsisModule } from "@app/shared/pipes/ellipsis/ellipsis.module";
import { DocIconComponent } from "@app/shared/components/document-collection/doc-icon/doc-icon.component";
import { DocActionComponent } from "@app/shared/components/document-collection/doc-action/doc-action.component";
import {
  DocumentActionComponent, DocumentActionFileComponent, DocumentMobileActionComponent,
  DocumentMobileActionMoreComponent,
} from "@app/pages/associations/components/associations-detail/documents";
import { DocSharedMoreComponent } from "@app/shared/components/document-collection/doc-shared-more/doc-shared-more.component";


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    MatCheckboxModule,
    FormsModule,
    EllipsisModule,
    DocIconComponent,
    DocActionComponent,
    DocSharedMoreComponent,
    DocumentActionComponent,
    DocumentMobileActionComponent,
    DocumentMobileActionMoreComponent,
    DocumentActionFileComponent,
    DocCardComponent
  ],
  exports: [
    DocCardComponent,
    DocIconComponent,
    DocActionComponent,
    DocSharedMoreComponent,
    DocumentActionComponent,
    DocumentMobileActionComponent,
    DocumentMobileActionMoreComponent,
    DocumentActionFileComponent,
    DocCardComponent
  ]
})
export class DocumentCollectionModule { }
