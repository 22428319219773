import { Component, Input, OnInit } from "@angular/core";

@Component({
  standalone: true,
  selector: 'app-text-label-desc',
  template: `
    <div class="desc"
         [class]="class"
    >
      <label class="tc-label fw-500" [class]="classLabel"
             [class.tc-black]="black">
        {{ label }}
      </label>
      <div class="tc-dust caption" [class]="classDesc">
          {{ desc }}
        <ng-content select="[innerContent]"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .desc { font-size: 14px}
      .desc.std label { color: #777777 !important; font-size: 14px; font-weight: 400!important }
      .desc.std .tc-dust { color: #222222 !important; font-weight: 500 }
      .desc.std-dark .tc-dust {color: #222222 !important}
      .desc.small label {font-size: 14px; font-weight: 500; color: #222222!important;}
      .desc { line-height:  1.5rem }
      .caption { font-size: 14px}
    `
  ]
})
export class TextLabelDescComponent implements OnInit {

  @Input()
  public label: string = '';

  @Input()
  public desc: string | null= '';

  @Input()
  public class: string = '';

  @Input()
  public classDesc: string = '';

  @Input()
  public classLabel: string = '';

  @Input()
  public inner: boolean = false;

  @Input()
  public black!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
