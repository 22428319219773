<div class="container-menu" [class.standalone]="isFull">
  <aside class="aside">
    <h4>{{ title | titlecase }}</h4>

    @if(logo) {
      <div class="logo">
        <a><img [src]="roamLogo" alt="Roam" /></a>
      </div>
    }

    <ng-content select="[logo]"></ng-content>

    <ng-content select="[menu]"></ng-content>

    <ng-content select="[bottom]"></ng-content>
  </aside>

  <main>
    <ng-content select="[content]"></ng-content>
  </main>
</div>
