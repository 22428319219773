import { Component, ViewChild } from "@angular/core";
import { DocumentPreviewHeaderComponent } from '../document-preview-header/document-preview-header.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { DocumentViewer } from "@app/shared/interfaces";
@Component({
  selector: 'app-document-preview-main',
  templateUrl: './document-preview-main.component.html',
  styleUrls: ['./document-preview-main.component.scss']
})
export class DocumentPreviewMainComponent {

  @ViewChild(DocumentPreviewHeaderComponent)
  private documentPreviewHeaderComponent!: DocumentPreviewHeaderComponent;

  public pdfSrc: any = '';
  public document!: DocumentViewer;
  public isCollapseComment!: boolean;

  public openComment(): void {
    this.isCollapseComment = !this.isCollapseComment;
    this.documentPreviewHeaderComponent.iconComments.forEach(icon => {
      icon.active = false;
    });
  }
  constructor(
    private dialogRef: MatDialogRef<DocumentPreviewMainComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DocumentViewer
  ) {
    this.pdfSrc = data.url;
    this.document = this.data;
  }

}
