  <div mat-dialog-title>
  <p class="fw-600">Add to Knowledge Base</p>

  <img
    mat-dialog-close
    alt="Close Icon"
    class="c-pointer"
    src="/assets/svg/close-icon.svg"
  />
</div>

<mat-dialog-content>
  <div class="d-grid gap-22">

    <app-knowledge-base-select (onSave)="saveNewName($event)"
    ></app-knowledge-base-select>

    <app-table [tableConfig]="tableConfig"
               [emptyRow]="true">
      <ng-template
        #customTemplate
        let-data="data"
        let-key="key"
        let-index="index"
      >
        <ng-container *ngIf="key === 'name'">
          <div class="align-center">
            <div class="align-center icon-file">
              <img
                alt="Icon"
                style="margin-right: 10px"
                [src]="getIconSource(data)"
              />
            </div>

            <div class="set-file-name">{{ data.name }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="key === 'action'">
          <img
            alt="Trash Icon "
            src="/assets/svg/ic-trash.svg"
            (click)="remove(data.id)"
          />
        </ng-container>
      </ng-template>
    </app-table>

    <p class="font-14px text-right">Maximum Token: 480/1200</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-between">
  <button mat-button mat-dialog-close class="close-btn">Cancel</button>

  <app-roam-button label="Save" (onClick)="submit()" [loader]="true"
                   [isLoading]="isLoading"
                   [disabled]="isLoading"
  />
</mat-dialog-actions>
