<div class="comment-chatbox">
  <div>
    <app-image-view [width]="'40px'" [rounded]="true" [type]="'user'"
                    [image]="''"
    ></app-image-view>
  </div>
  <div class="comment-input">
    <textarea name="comment" id="comment"
              placeholder="Reply"
    ></textarea>
    <div class="toolbar pt-10">
      <div class="d-flex gap-16">
        <span class="d-flex mt-4" *ngFor="let tool of toolbars"
              [inlineSVG]="tool.icon"></span>
      </div>
      <div class="btn-send">
        <span class="d-flex svg-16" [inlineSVG]="icon.sendWhite.src"></span>
      </div>
    </div>
  </div>
</div>
