import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-roam-dialog',
  templateUrl: './roam-dialog.component.html',
  styleUrls: ['./roam-dialog.component.scss']
})
export class RoamDialogComponent implements OnInit {

  @Input()
  public title: string = '';

  @Output()
  public onClose: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public icon = iconLib;

  constructor() { }

  ngOnInit(): void {
  }

  public close(): void {
    this.onClose.emit()
  }

}
