import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
  ]
})
export class FilterDialogComponent implements OnInit {
  filters = [
    {
      name: 'Past Bookings',
      checked: false
    },
    {
      name: 'Currently Hosting',
      checked: false
    },
    {
      name: 'Reviews left in last 14 days',
      checked: false
    },
    {
      name: 'Created in the last 7 days',
      checked: false
    },
    {
      name: 'Recently canceled',
      checked: false
    },
    {
      name: 'Recently Altered',
      checked: false
    },
    {
      name: 'Upcoming Bookings',
      checked: false
    },
    {
      name: 'Reviews left in last 7 days',
      checked: false
    },
    {
      name: 'Checked out past 7 days',
      checked: false
    },
    {
      name: 'Past Bookings',
      checked: false
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
