import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-checkbox-select-list',
  templateUrl: './checkbox-select-list.component.html',
  styles:[
      `
      .checkbox-select-list {
        padding: 0.5rem 0;
        margin: 0.25rem 0;
        border-radius: 0.5rem;
      }

      .checkbox-select-list:hover {
        background-color: #F5F5F5;
      }

      .checkbox-select-list .mat-mdc-checkbox {
        width: 100%;
      }

      .checkbox-select-list:has(.mat-mdc-checkbox-checked ){
        background-color:#F4ECF0;
      }

      ::ng-deep .checkbox-select-list .mdc-checkbox{
        visibility: hidden!important;
      }

      ::ng-deep .checkbox-select-list:hover .mdc-checkbox {
        visibility: visible!important;
      }

      ::ng-deep .checkbox-select-list .mdc-form-field {
        width: 100%;
        padding-right: 0.5rem;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      ::ng-deep .checkbox-select-list .mdc-label {
        width: 100%;
      }

      ::ng-deep .mat-mdc-checkbox-checked .mdc-checkbox{
        visibility: visible!important;
      }
    `
  ]
})
export class CheckboxSelectListComponent {

  @Input()
  public checked!: boolean;

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter<any>();
}
