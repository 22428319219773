import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'accountingNegative'
})
export class AccountingNegativePipe implements PipeTransform {

  transform(value: number): string {
    if (value < 0) {
      return `(${Math.abs(value)})`;
    }
    return value.toString();
  }
}
