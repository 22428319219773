import { Component, Inject } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from "@app/shared/components/button/button/button.component";
import { RoamTextAreaComponent } from '../../roam-text-area/roam-text-area.component';

@Component({
  selector: 'app-shared-link-dialog',
  standalone: true,
  imports: [
    CommonModule, RoamIconComponent, ButtonComponent,
    MatDialogModule, RoamTextAreaComponent],
  templateUrl: './shared-link-dialog.component.html',
  styles:[`
    .area {
      width: 100%;
      padding: 8px 12px;
      border-radius: 8px;
      border: 0.6px solid #D6D3D7;
      background: #FFF;
      height: 72px;
      font-family: "Poppins", sans-serif;
    }

    .area::placeholder {
      font-size: 14px;
    }
  `]
})
export class SharedLinkDialogComponent {
  public title!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string}
  ) {
    this.title = data['title'];
  }
}
