import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: 'app-roam-form-info',
  imports: [CommonModule, InlineSVGModule],
  template:`
    <div class="form-info"
         [class.dark]="bg === 'dark'"
    >
      <div class="align-center gap-16 title" *ngIf="title"
      >
        <div class="justify-between"
             [class.w-100]="isDelete">
          <h6 class="fw-500">
            {{ title }}
          </h6>
          <div *ngIf="isDelete" class="icon-24 c-pointer" (click)="onDelete.emit()">
            <span [inlineSVG]="icon.binRed.src"></span>
          </div>
        </div>
        <div class="d-flex icon-24" *ngIf="checkmark">
          <span [inlineSVG]="icon.checkTimes.checkRoundGreen.src"></span>
        </div>
      </div>
      <ng-content select="[content]"></ng-content>
    </div>
  `,
  styles:[
    `
      .form-info {
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 8px;
        margin-top: 1rem;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      }

      .form-info.dark {
        background-color: #F5F5F5;
        margin-top: 0;
      }

      .form-info .title {
        margin-bottom: 16px;
      }

      ::ng-deep .icon-24 svg {
        width: 24px;
        height: 24px;
      }
    `
  ]
})
export class RoamFormInfoComponent {

  @Input()
  public title!: string;

  @Input()
  public checkmark: boolean = false;

  @Input()
  public isDelete: boolean = false;

  @Output()
  public onDelete: EventEmitter<Event> = new EventEmitter<Event>();

  @Input()
  public bg: 'dark' | 'white' = 'white'

  public icon = iconLib;
}
