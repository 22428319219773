import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareActionComponent } from "@app/shared/components/share-action/share-action/share-action.component";
import { SharedLinkDialogComponent } from "@app/shared/components/share-action/shared-link-dialog/shared-link-dialog.component";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ShareActionComponent,
    SharedLinkDialogComponent
  ],
  exports: [
    ShareActionComponent,
    SharedLinkDialogComponent
  ]
})
export class ShareActionModule { }
