<div class="flex-column-height overflow-hidden">
  <app-document-preview-header (onOpenComment)="isCollapseComment = !isCollapseComment"
                               [isCommentOpen]="isCollapseComment"
                               [document]="document"
  >
  </app-document-preview-header>

  <div class="flex-1" >
    <div class="d-flex h-100">
      <div class="flex-1">
        <div *ngIf="pdfSrc.length > 0">
          <ngx-extended-pdf-viewer
                  [(src)]="pdfSrc"
                  [zoom]="'auto'"
                  [height]="'auto'"
                  [showHandToolButton]="true"
                  [backgroundColor]="'#f8f9fd'"
          />
        </div>
      </div>
      <div class="comment-section"
           [class.expand]="!isCollapseComment"
      >
        <app-document-preview-comment (onMenuCollapse)="openComment()">
        </app-document-preview-comment>
      </div>
    </div>
  </div>

</div>
