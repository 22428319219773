import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FileViewActionComponent } from "@app/shared/components/file-config/file-view-action/file-view-action.component";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { EllipsisModule } from "@app/shared/pipes/ellipsis/ellipsis.module";
import { FileExtensionPipe } from "@app/shared/pipes/file-extension.pipe";

@Component({
  selector: 'file-view',
  standalone: true,
  imports: [
    CommonModule, FileViewActionComponent, RoamIconComponent,
    EllipsisModule, FileExtensionPipe
  ],
  templateUrl: './file-view.component.html',
  styles:[`
    .file-view {
      padding: 8px;
      border-radius: 10px;
      border: 1px solid #E8E4E9;
      background: #ffffff;
      cursor: pointer;
    }

    .file-view header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0 12px 0;
    }

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
    }

    .preview {
      width: 100%;
      height: 160px;
      border: 1px solid #E8E4E9;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `]
})
export class FileViewComponent implements OnInit{
  @Input()
  public id!: string;

  @Input()
  public name!: string;

  @Input()
  public date: any;

  @Input()
  public url!: string;

  public isHover!: boolean;

  ngOnInit(): void {
  }
}
