import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-document-share-dialog",
  templateUrl: "./document-share-dialog.component.html",
  styleUrls: ["./document-share-dialog.component.scss"],
})
export class DocumentShareDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DocumentShareDialogComponent>) {}

  ngOnInit(): void {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
