import { booleanAttribute, Component, EventEmitter, input, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RoamButtonComponent } from "../button/roam-button/roam-button.component";

@Component({
  standalone: true,
  selector: "app-container-dialog-set",
  templateUrl: "./container-dialog-set.component.html",
  styleUrls: ["./container-dialog-set.component.scss"],
  imports: [MatProgressSpinnerModule, RoamButtonComponent]
})
export class ContainerDialogSetComponent {
  @Input()
  public isLoading!: boolean;
  readonly isValid = input(false, { transform:booleanAttribute });
  readonly buttonLabel = input("Save");

  @Output()
  public onSave: EventEmitter<Event> = new EventEmitter<Event>();
  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();
}
