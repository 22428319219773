import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from "@angular/router";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { BreadcrumbService } from "@app/shared/components/roam-breadcrumb/breadcrumb.service";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";

@Component({
  selector: 'roam-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, RoamIconComponent, RoamLoaderModule],
  templateUrl: './roam-breadcrumb.component.html',
  styleUrls: ['./roam-breadcrumb.component.scss']
})
export class RoamBreadcrumbComponent implements OnDestroy{

  @Input()
  public isLoading!: boolean;

  constructor(
    public breadcrumbs: BreadcrumbService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.breadcrumbs.breadcrumb = [];
  }

  public navigateTo(path: string): void {
    this.router.navigate([path],{
      queryParamsHandling: 'merge'
    });
  }
}
