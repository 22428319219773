import { Component, EventEmitter, Input, Output} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { docPreviewHeaderIcon } from "@app/pages/document-preview/shared/const/const";
import { DocumentViewer } from "@app/shared/interfaces";

@Component({
  selector: 'app-document-preview-header',
  template: `
    <div class="preview-header justify-between">
      <div class="align-center gap-22">
        <span class="d-flex c-pointer" [inlineSVG]="icon.closeBlack.src"
              mat-dialog-close></span>
        <app-file-card [name]="document.title"
                       [type]="'pdf'"
                       [dateSize]="(document.updateAt | dateTime : 'dateMMMMDDComa' ) +' • ' + (document.size | bytesize)"
                       [noBorder]="true"
        ></app-file-card>
      </div>
      <div class="align-center gap-22">
        <div class="align-center gap-22">
          <span class="d-flex c-pointer icon" *ngFor="let icon of iconComments; let icon_i = index"
                [inlineSVG]="icon.icon"
                [class.comment-open]="icon.active"
                (click)="actionClick(icon.id, icon_i)"
          ></span>
        </div>
        <app-roam-button [loader]="false"
                         [icon]="icon.shareWhite"
                         [label]="'Share'"
        ></app-roam-button>
      </div>
    </div>

  `,
  styles: [
      `
      .preview-header {
        padding: 0.875rem 1.5rem;
        box-shadow: 4px 4px 10px 0 rgba(23, 73, 77, 0.03);
      }

      .preview-header .icon {
        padding: 4px;
      }

      .comment-open {
        border-radius: 4px;
        background: rgba(145, 70, 106, 0.15);
        fill: #91466A;
      }
      
      ::ng-deep .preview-header .file-card {
        max-width: unset;
      }
      
      ::ng-deep .preview-header .file-card .file-name {
        max-width: unset;
      }
    `
  ]
})
export class DocumentPreviewHeaderComponent{

  @Input()
  public document!: DocumentViewer;

  @Input()
  public isCommentOpen!: boolean;

  @Output()
  public onOpenComment: EventEmitter<Event> = new EventEmitter<Event>();

  public icon = iconLib;
  public iconComments = docPreviewHeaderIcon;

  public actionClick(id: string, index: number) {
    if(id === 'comment') {
      this.openComment(index);
    }
  }

  public openComment(index:number): void {
    this.isCommentOpen = !this.isCommentOpen
    this.onOpenComment.emit()
    this.iconComments[index].active = !this.isCommentOpen;
  }
}
