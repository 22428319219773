<div>
  <ul class="align-center">
    <li class="align-center fw-500 font-20px"
        *ngFor="let breadcrumb of (breadcrumbs.breadcrumb$ | async) ; let last = last">
      <a *ngIf="!last" (click)="navigateTo(breadcrumb.path)"
         class="tc-dust c-pointer">
        {{ breadcrumb.name }}
      </a>
      <span *ngIf="last">
        <span *ngIf="!(breadcrumbs.loading$ | async)">{{ breadcrumb.name }}</span>
        <span *ngIf="breadcrumbs.loading$ | async">
          <app-loader-dot [size]="3"/>
        </span>
      </span>

      <div class="mr-8 mt-2" *ngIf="!last">
        <roam-icon name="chevron-right" color="gray" size="14"></roam-icon>
      </div>
    </li>
  </ul>
</div>
