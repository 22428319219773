<div class="filter-container">
  <header>
    <div class="c-pointer" (click)="sidebar.triggerCloseSidebar()">
      <roam-icon name="close" color="gray"></roam-icon>
    </div>
    <h3>Filters</h3>
  </header>
  <div class="filter-container-body">
    <div class="content-body">
      <ng-content></ng-content>
    </div>

    <div class="justify-between mt-40" *ngIf="showButton">

      <app-button label="Clear Filter" type="underline md"
                  (onClick)="clear.emit()"
      ></app-button>

      <app-button label="Search Filter" type="btn-primary md"
                  (onClick)="filter.emit(); sidebar.triggerCloseSidebar()"
      ></app-button>
    </div>
  </div>
</div>
