import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { docActions } from "@app/shared/components/document-collection/shared/const/doc-action-list.const";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ROAM_ICON } from "@app/core/const/app-roam.const";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  standalone: true,
  imports:[CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, RoamIconComponent],
  selector: 'app-doc-card',
  templateUrl: './doc-card.component.html',
  styleUrls: ['./doc-card.component.scss']
})
export class DocCardComponent {

  @Input()
  public title: string = '';

  @Input()
  public date: string = '';

  @Input()
  public by: string = '';

  @Input()
  public isSelected?: boolean = false;

  @Input()
  public isHover: boolean = false;

  @Input()
  public isShowCheckbox: boolean = false;

  @Input()
  public item: any = '';

  @Input()
  public noCheckbox: boolean = false;

  @Input()
  public customIcon!: string;

  @Output()
  public onSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public onFolder: EventEmitter<Event> = new EventEmitter<Event>();


  public icon = iconLib;
  public docActions: IIdNameIcon[] = docActions;
  public isAction: boolean = false;

  public getIconSource(file: any | undefined): string {
    const extensionIcons: { [key: string]: string } = {
      DOC: '/assets/svg/flat-color-icons_folder.svg',
      PDF: '/assets/svg/bi_file-pdf.svg',
      KNB: `${ROAM_ICON}/diamond-primary.svg`
    };

    if (!file?.extension && !this.customIcon) {
      return '/assets/svg/flat-color-icons_folder.svg';
    }


    const upperCaseExtension = this.customIcon ? this.customIcon.toUpperCase() : file.extension.toUpperCase();
    return extensionIcons[upperCaseExtension] || '/assets/svg/flat-color-icons_file.svg';
  }


}
