import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'text-label-bg-icon',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <div class="d-flex gap-16">
      <div class="icon-bg">
        <roam-icon [name]="icon" [color]="color"/>
      </div>
      <div>
        <h6 class="fw-500 tc-black">
          {{ name }}
        </h6>
        <div *ngIf="desc" class="tc-dust font-14px">
          {{ desc }}
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./text-label-bg-icon.component.scss']
})
export class TextLabelBgIconComponent {

  @Input()
  public name!: string

  @Input()
  public desc!: string;

  @Input()
  public icon!: string;

  @Input()
  public color: string = 'primary';

}
