import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-card-file-preview',
  templateUrl: './card-file-preview.component.html',
  styleUrls: ['./card-file-preview.component.scss']
})
export class CardFilePreviewComponent implements OnInit {

  @Input()
  public id: string = '';

  @Input()
  public name: string = '';

  @Input()
  public extension: string = '';

  @Output()
  public onDelete: EventEmitter<number | string> = new EventEmitter<number |  string>()

  public icon = iconLib;

  constructor() { }

  ngOnInit(): void {
  }

  public deleteDelete(id: number | string): void {
    this.onDelete.emit(id)
  }
}
