import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: Date | string, format: 'short' | 'long' = 'short'): string {
    const now = new Date();
    const time = new Date(value);
    const seconds = Math.floor((now.getTime() - time.getTime()) / 1000);

    if (seconds < 59) {
      return 'Just now';
    }

    const intervals: { [key: string]: [number, string, string] } = {
      'yr': [31536000, 'year', 'years'],
      'mo': [2592000, 'month', 'months'],
      'wk': [604800, 'week', 'weeks'],
      'd': [86400, 'day', 'days'],
      'h': [3600, 'hour', 'hours'],
      'm': [60, 'minute', 'minutes']
    };


    let counter;
    for (const unit in intervals) {
      counter = Math.floor(seconds / intervals[unit][0]);
      if (counter > 0) {
        return format === 'short'
          ? `${counter}${unit}`
          : `${counter} ${counter === 1 ? intervals[unit][1] : intervals[unit][2]} ago`;
      }
    }

    return 'Just now';
  }
}
