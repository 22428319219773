import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: '[appSeeMore]'
})
export class SeeMoreDirective implements OnInit{

  @Input()
  public isShowMore: boolean = false;

  constructor(private el: ElementRef,
              private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.initElement();
  }

  public initElement(): void {
    this.renderer.setStyle(this.el.nativeElement, 'max-height', '100px');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');

    if(this.isShowMore) {
      this.renderer.addClass(this.el.nativeElement, 'see-more-after');
    }
  }

}
