import { ICardIconSelect } from "@app/shared/interfaces/card-select.interface";
import { environment } from '@environments/environment'
import { IModule } from "@app/shared/interfaces";

const iconPath = '/assets/svg';
const iconPathNav = '/assets/svg/sidenav';

export enum associationMenu {
  GENERAL_INFORMATION = 'general_information',
  COMPLIANCE = 'compliance',
  ACCOUNTING = 'accounting',
  MANAGEMENT = 'management',
  BOARD = 'board',
  COMMITTEES = 'committees',
  MEETING = 'meeting',
  LEASING = 'leasing',
  PORTAL = 'portal',
  PRINT = 'print',
}

const BASE_ASSOCIATION_MENUS: IModule[] = [
  {
    id: associationMenu.GENERAL_INFORMATION,
    name: 'General',
    slug: 'general',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: associationMenu.COMPLIANCE,
    name: 'Compliance',
    slug: 'reporting',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: associationMenu.ACCOUNTING,
    name: 'Accounting',
    slug: 'financials',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: associationMenu.MANAGEMENT,
    name: 'Management',
    slug: 'policy',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
];
if (environment.showAllMenus) {
  BASE_ASSOCIATION_MENUS.push(
    {
      id: associationMenu.BOARD,
      name: 'Board',
      slug: 'group',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
    {
      id: associationMenu.COMMITTEES,
      name: 'Committees',
      slug: 'briefcase',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
    {
      id: associationMenu.MEETING,
      name: 'Meetings',
      slug: 'calendartime',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
    {
      id: associationMenu.LEASING,
      name: 'Leasing',
      slug: 'key',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
    {
      id: associationMenu.PORTAL,
      name: 'Portal',
      slug: 'portal',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
    {
      id: associationMenu.PRINT,
      name: 'Printing',
      slug: 'printer',
      code: '',
      status: 0,
      createdAt: '',
      updatedAt: '',
      order: 0
    },
  );
}
export const ASSOCIATION_MENUS = BASE_ASSOCIATION_MENUS;

export const generalInfoCard: ICardIconSelect[] = [
  {
    name: 'Single Family',
    icon: `${iconPathNav}/associations.svg`,
    isSelect: false,
  },
  {
    name: 'Multi Family',
    icon: `${iconPath}/properties/ic-multifamily.svg`,
    isSelect: false
  },
  {
    name: 'Condo',
    icon: `${iconPath}/properties/ic-condo.svg`,
    isSelect: false
  },
  {
    name: 'Townhome',
    icon: `${iconPath}/properties/ic-townhome.svg`,
    isSelect: false
  },
  {
    name: 'Camper/RV',
    icon: `${iconPath}/properties/ic-camper.svg`,
    isSelect: false
  },
  {
    name: 'Other',
    icon: `${iconPath}/properties/ic-other.svg`,
    isSelect: false
  },
  {
    name: 'Barn',
    icon: `${iconPath}/properties/ic-barn.svg`,
    isSelect: false
  },
  {
    name: 'Container',
    icon: `${iconPath}/properties/ic-container.svg`,
    isSelect: false
  }

]
