import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxSelectUserComponent } from './checkbox-select-user/checkbox-select-user.component';
import { RoamLayoutModule } from "@app/shared/components/roam-layout/roam-layout.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CheckboxSelectListComponent } from './checkbox-select-list/checkbox-select-list.component';
import { RoamTextLabelModule } from "@app/shared/components/roam-text-label/roam-text-label.module";
import { FormsModule } from "@angular/forms";



@NgModule({
  declarations: [
    CheckboxSelectUserComponent,
    CheckboxSelectListComponent
  ],
  imports: [
    CommonModule,
    RoamLayoutModule,
    MatCheckboxModule,
    RoamTextLabelModule,
    FormsModule
  ],
  exports: [
    CheckboxSelectUserComponent,
    CheckboxSelectListComponent
  ]
})
export class CheckboxModule { }
