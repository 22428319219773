<mat-card
  appearance="outlined"
  class="shortcut-menu-card c-pointer"
  [ngClass]="{ active: shortcut.focus }"
  [class.coming]="shortcut.comingSoon"
>
  <div class="coming-text" *ngIf="shortcut.comingSoon">(coming soon)</div>
  <div class="icon-card">
    <img [src]="shortcut.icon" [alt]="shortcut.title + 'Icon'" />
  </div>
  <p class="title">{{ shortcut.title }}</p>
</mat-card>
