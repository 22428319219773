import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  imports:[CommonModule, MatIconModule],
  selector: 'app-icon-round',
  template:`
    <div class="icon" [class]="class">
      <img [src]="src" [alt]="alt" (click)="click()"
           [style.width]="width"
           [style.height]="height"
      >
    </div>
  `,
  styles:[
      `.icon {
      min-width: 40px;
      min-height: 40px;
      border: 1px solid #767676;
      display: flex;
      justify-content: center;
      border-radius: 12px;
      cursor: pointer;
    }
    `
  ]
})
export class IconRoundComponent implements OnInit {

  @Input()
  public src: string = '';

  @Input()
  public alt: string = 'icon';

  @Input()
  public width: string = '';

  @Input()
  public height: string = '';

  @Input()
  public class: string = '';

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  public click(): void {
    this.onClick.emit();
  }

}
