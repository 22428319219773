import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'create-new-plus',
  standalone: true,
  imports: [CommonModule, RoamIconComponent],
  template:`
    <div class="create-new-plus align-center gap-12 c-pointer"
         (click)="onClick.emit()">
      <div class="add">
        <roam-icon name="plus" size="20" color="white"></roam-icon>
      </div>
      <div class="fw-500 tc-black font-14px"> {{ label }} </div>
    </div>
  `,
  styles:[
      `
      .create-new-plus {
        margin: 8px 0;
      }

      .add {
        width: 32px;
        height: 32px;
        background: rgba(145, 70, 106, 1);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  ]
})
export class CreateNewPlusComponent {
  @Input()
  public label: string = 'Create New';

  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();
}
