<header class="align-center">
  <span class="d-flex svg-20 mt-3 c-pointer"
        [inlineSVG]="icon.closeBlack.src"
        (click)="onMenuCollapse.emit()"
  >
  </span>
  <h1 class="font-18px fw-500 flex-1 text-center">Comment</h1>
</header>
<div class="flex-column-height">
  <div class="flex-1 comment-body">
    <app-comment *ngFor="let comment of comments"
                 [user]="comment.user"
                 [isPrivate]="comment.isPrivate"
                 [picture]="comment.photo"
                 [comment]="comment.comment"
                 [recentTime]="comment.time"
    ></app-comment>
  </div>
  <div class="comment-chat-box">
    <app-comment-chat-box></app-comment-chat-box>
  </div>
</div>
