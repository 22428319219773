import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-roam-log-activity",
  templateUrl: "./roam-log-activity.component.html",
  styleUrls: ["./roam-log-activity.component.scss"],
})
export class RoamLogActivityComponent implements OnInit {
  @Output()
  public openEditForm: EventEmitter<Event> = new EventEmitter();

  @Input()
  public logs!: any[];

  @Input()
  public totalLogs: number = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log(this.logs);
    
  }
}
