<div class="search-filter">
  <app-search-input (onSearch)="search.emit($event)"></app-search-input>

  <app-button-filter [label]="'Filter'"
                     (onClick)="onFilter.emit()"></app-button-filter>

  <app-button-filter *ngIf="isClear"
                     [label]="'Clear Filter'" [isFilter]="false"
                     (onClick)="onFilter.emit(false)"
  ></app-button-filter>
</div>
