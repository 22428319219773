<app-roam-card [title]="'Audit Log'">
  <div actRef>
    <button class="btn btn-o-primary align-center gap-8 mr-0">
      <roam-icon name="eye" size="12" color="primary" />
      View All
    </button>
  </div>
  <ng-container cardContent>
    @if (logs && logs.length > 0) {
      <div class="activity" *ngFor="let log of logs">
        <div class="log">
          <span>{{ log.verb }}</span>
          <span>{{ log.objectType }}</span>
          <span>{{ log.objectName }}</span>
          <span>by {{ log.userName }}</span>
          <span>on {{ log.createdAt | date }}</span>
        </div>
      </div>
    } @else {
      <div class="tc-grey font-14">Audit Log Not Available</div>
    }
  </ng-container>
</app-roam-card>
