import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { RoamDateFormatter } from "@app/shared/pipes/date-time-pipe/date-time-provider/roam-date-formatter.provider";

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(
    public roamDateFormatter: RoamDateFormatter,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }


  transform(
    date: Date | string,
    method: string,
    locale: string = this.locale
  ): string {
    // @ts-ignore
    if(typeof this.roamDateFormatter[method] === 'undefined' ) {
      const allowedMethods = Object.getOwnPropertyNames(
        Object.getPrototypeOf(RoamDateFormatter.prototype)
      ).filter((iMethod) => iMethod !== 'constructor');
      throw new Error(
        `${method} is not a valid date formatter. Can only be one of ${allowedMethods.join(
          ', '
        )}`
      );
    }

    // @ts-ignore
    return date && date.toString().toLowerCase() !== 'Invalid Date'.toLowerCase() ? this.roamDateFormatter[method]({
      date,
      locale
    }) : 'N/A'
  }

}
