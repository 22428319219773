import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DebounceInputDirective } from "@app/shared/directives/input-directive/debounce-input.directive";

@Component({
  standalone:true,
  imports:[CommonModule, FormsModule, DebounceInputDirective],
  selector:'app-search-input',
  template:`
    <div class="search-input"
         [style.width]="!stretch ? '350px' : 'unset'"
    >
      <input appDebounceInput
             placeholder="Search" [(ngModel)]="searchText"
             (onEvent)="searchInput()">
    </div>
  `,
  styles: [
    `
      .search-input input {
        width: 100%;
        // min-width: 350px;
        padding: 11px;
        border-radius: 9px;
        border: 1px solid #D6D3D7;
        background-color: #ffffff;
      }

      .search-input input::placeholder {
        color: #949494;
        font-family: "Poppins", sans-serif;
      }
    `
  ]
})
export class SearchInputComponent {

  @Input()
  stretch: boolean = false;

  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>()

  public searchText: string = ''

  public searchInput(): void {
    this.onSearch.emit(this.searchText)
  }
}
