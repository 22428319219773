import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";

@Component({
  selector: 'user-view-more',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, ImageViewComponent],
  template:`
    <div class="align-center">
      <div class="avatar-stack">
        <div class="image-items">
          <img *ngFor="let user of users.slice(0, 3)"
               [class.space]="users.length < 4"
               [matTooltip]="user.name"
               [src]="user.avatarUrl || 'assets/svg/ic-user-round.svg'"
               [alt]="user.name">
        </div>

        <div class="item" *ngIf="users.length > 3">
          +{{ users.length - 3 }}
        </div>

        <div class="ml-8" *ngIf="users.length > 3">
          {{ users[3].name }} & {{ users.length - 3}} others
        </div>
      </div>
    </div>
  `,
  styles:[`
    .avatar-stack {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
    }

    .image-items {
      display: flex;
      flex-shrink: 0;
    }

    .image-items img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 2px solid white;
      overflow: hidden;
      background-color: #ffffff;
    }

    .image-items img:not(:first-child) {
       margin-left: -15px;
     }

    .image-items img.space:not(:first-child) {
      margin-left: -8px!important;
    }

    .item {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #F5F5F5;
      border: 2px solid white;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      font-weight: 800;
      margin-left: -15px;
    }
  `]
})
export class UserViewMoreComponent {
  @Input()
  public users: any[] = [] // TODO Interface
}
