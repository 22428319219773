import { associationMenu } from "@app/core/const/associations/association-menu.const";
import { iconLib } from "@app/core/const/roam-icon";
import { IIdNameIcon } from "@app/shared/interfaces/id-name.interface";

export const associationListQuickAction: IIdNameIcon[] = [
  {
    id: "add",
    name: "Add Association",
  },
];

const associationSummaryQuickAction: IIdNameIcon[] = [
  {
    id: associationMenu.GENERAL_INFORMATION,
    name: "Edit Association",
  },
  {
    id: associationMenu.ACCOUNTING,
    name: "Edit Accounting Settings",
  },
];

const associationUnitQuickAction: IIdNameIcon[] = [
  {
    id: "add-unit",
    name: "Add Unit",
  },
  {
    id: "calendar",
    name: "View Calendar",
  },
];

export const associationDetailQuickAction: IIdNameIcon[][] = [
  [...associationSummaryQuickAction],
  [...associationUnitQuickAction],
];
