import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { IResponseDTO } from "@app/shared/interfaces/response.interface";
import { ITableColumn } from "@app/shared/interfaces/table.interface";
import { SourceTableColumnType } from "./components/cell-table/cell-table.model";
import { CellTableModule } from "./components/cell-table/cell-table.module";

@Component({
  standalone: true,
  selector: "app-roam-table",
  templateUrl: "./roam-table.component.html",
  styleUrls: ["./roam-table.component.scss"],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    CellTableModule,
  ]
})
export class RoamTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() updatePageLoadData: EventEmitter<PageEvent> = new EventEmitter();
  @Output() rowClick: EventEmitter<string> = new EventEmitter();
  @Input() tableColumns: ITableColumn[] = [];
  @Input() data!: IResponseDTO<any>;
  @Input() loading: boolean = false;

  public dataSource = new MatTableDataSource<any>();
  public displayedColumns: string[] = [];
  public pageSize = 10;
  public pageIndex = 0;
  public type = SourceTableColumnType;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"] && this.data) {
      this.dataSource.data = this.data.data;
    }

    if (changes["tableColumns"]) {
      this.displayedColumns = this.tableColumns.map(
        (item: ITableColumn) => item.name,
      );
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.updatePageLoadData.emit(event);
  }

  public onRowClick(id: string) {
    this.rowClick.emit(id);
  }

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  protected readonly Number = Number
}
