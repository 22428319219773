import { Component, EventEmitter, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "@app/shared/components/button/button/button.component";

@Component({
  selector: 'filter-content',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  template:`
    <div>

      <ng-content></ng-content>

      <div class="action">
        <div class="justify-between pr-8 pl-8">
          <button class="btn underline"
                  (click)="clear.emit()">Clear Filter</button>

          <app-button label="Search Filters"
                      (click)="search.emit()"></app-button>
        </div>
      </div>
    </div>
  `,
  styles:[
    `
      .action {
        padding: 1rem 0 0 0;
        border-top: 1px solid #ccc;
        margin: 1rem -1rem -0.5rem -1rem;
      }
    `
  ]
})
export class FilterContentComponent {
  @Output()
  public clear: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public search: EventEmitter<Event> = new EventEmitter<Event>();
}
