<div>
    <ng-container *ngIf="data && !loading">
        <table mat-table [dataSource]="dataSource" class="bordered-table bills-table">
            <ng-container *ngFor="let item of tableColumns; trackBy: trackItem">
                <ng-container [matColumnDef]="item.name">
                    <th mat-header-cell *matHeaderCellDef>{{ item.title | titlecase }}</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="item.subArr; else noSubArr">
                            {{ element[item.name]?.length ? element[item.name][0][item.subArr] : '-' }}
                        </ng-container>
                        <ng-template #noSubArr>
                            <ng-container *ngIf="item.subName; else noSubName">
                                {{ element[item.name][item.subName].length ? element[item.name][item.subName] : '-' }}
                            </ng-container>
                        </ng-template>
                        <ng-template #noSubName>
                            <app-cell-table [type]="item.component ?? type.text" [content]="element[item.name]" [col]="item" [cell]="element"></app-cell-table>
                        </ng-template>
                    </td>
                </ng-container>
            </ng-container>

            <ng-content select="[action]"></ng-content>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row.id)"></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[10, 25, 50, 100]"
            [pageSize]="pageSize"
            (page)="handlePageEvent($event)"
            [length]="data.meta.total"
        >
        </mat-paginator>
    </ng-container>

    <div *ngIf="loading" class="justify-center loader">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>
