<div mat-dialog-title class="d-flex align-center justify-between">
  <h1 class="font-24px">
    {{ title }}
  </h1>
  <span class="c-pointer" [inlineSVG]="icon.closeBlack.src"
        (click)="close()"
  >
  </span>
</div>
<hr>
<div mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</div>
<div mat-dialog-actions>
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
