import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  standalone: true,
  selector: 'app-roam-layout-content-legacy',
  templateUrl: './roam-layout-content-legacy.component.html',
  styleUrls: ['./roam-layout-content-legacy.component.scss'],
  imports: [CommonModule, MatSidenavModule]
})
export class RoamLayoutContentLegacyComponent {

  public isExpand: boolean = true;

  public expandMenuAction(): void {
    this.isExpand = !this.isExpand
  }
}
