import { Component, OnInit } from '@angular/core';
import { iconLib } from "@app/core/const/roam-icon";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports:[CommonModule],
  selector: 'app-document-shared-action',
  templateUrl: './document-shared-action.component.html',
  styleUrls: ['./document-shared-action.component.scss']
})
export class DocumentSharedActionComponent implements OnInit {

  public icon = iconLib;

  constructor() { }

  ngOnInit(): void {
  }

}
