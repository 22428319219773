import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from "@app/shared/interfaces/breadcrumb.interface";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbSubject: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>([]);
  breadcrumb$: Observable<IBreadcrumb[]> = this.breadcrumbSubject.asObservable();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor() { }

  set breadcrumb(value: IBreadcrumb[]) {
    this.breadcrumbSubject.next(value);
  }

  get breadcrumb() {
    return this.breadcrumbSubject.value;
  }
}
