import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextLabelIconComponent } from './text-label-icon.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { TextLabelListComponent } from './text-label-list.component';
import { TextStatusComponent } from "@app/shared/components/roam-text-label/text-status/text-status.component";
import { TextIconBgComponent } from "@app/shared/components/roam-text-label/text-icon-bg/text-icon-bg.component";
import { TextAvatarLabelComponent } from "@app/shared/components/roam-text-label/text-avatar-label/text-avatar-label.component";
import { TextLabelBgIconComponent } from "@app/shared/components/roam-text-label/text-label-bg-icon/text-label-bg-icon.component";


@NgModule({
  declarations: [
    TextLabelIconComponent,
    TextLabelListComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    TextStatusComponent,
    TextIconBgComponent,
    TextAvatarLabelComponent,
    TextLabelBgIconComponent
  ],
  exports: [
    TextLabelIconComponent,
    TextLabelListComponent,
    TextStatusComponent,
    TextIconBgComponent,
    TextAvatarLabelComponent,
    TextLabelBgIconComponent
  ]
})
export class RoamTextLabelModule { }
