<button mat-icon-button (click)="onStartEdit()" *ngIf="!isEditMode; else editMode">
    <mat-icon class="c-pointer">edit</mat-icon>
</button>

<ng-template #editMode>
    <div class="justify-center gap-9">
        <button mat-icon-button (click)="onCancelEdit()">
            <mat-icon class="circle-icon">clear</mat-icon>
        </button>
        <button mat-icon-button (click)="onSaveEdit()">
            <mat-icon class="circle-icon done-icon">done</mat-icon>
        </button>
    </div>
</ng-template>