<div mat-dialog-title>
  <p class="fw-600">Share “File Name”`</p>
  <img
    src="/assets/svg/close-icon.svg"
    alt="Close Icon"
    class="c-pointer"
    mat-dialog-close
  />
</div>
<mat-dialog-content class="document-email-dialog-content">
  <form [formGroup]="form">
    <div class="w-100 mb-20px">
      <mat-label class="fw-400"> Email Shared Link </mat-label>

      <mat-form-field appearance="outline" class="white-bg w-100">
        <mat-chip-listbox #chipList>
          <mat-chip-option
            *ngFor="let keyword of emailList"
            (removed)="removeKeyword(keyword)"
          >
            <img
              matChipAvatar
              src="/assets/svg/avatar-chips.svg"
              alt="Avatar"
            />
            {{ keyword }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
<!--          <input-->
<!--            placeholder="New keyword..."-->
<!--            [matChipInputFor]="chipList"-->
<!--            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--            [matChipInputAddOnBlur]="true"-->
<!--            (matChipInputTokenEnd)="addKeywordFromInput($event)"-->
<!--          />-->
        </mat-chip-listbox>
      </mat-form-field>
    </div>

    <div class="w-100 mb-20px">
      <mat-label class="fw-400"> Messages (Optional) </mat-label>

      <mat-form-field appearance="outline" class="white-bg w-100">
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Lorem ipsum dummy text"
          formControlName="message"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="document-email-dialog-actions">
  <button mat-button class="close-btn" mat-dialog-close>Cancel</button>
  <button mat-button class="continue-btn" (click)="onSubmit()">Save</button>
</mat-dialog-actions>
