import { Component, EventEmitter, Input, Output } from "@angular/core";
import { getFileIcon } from "../shared/utils/file-word-masking.util";

@Component({
  selector: 'app-folder-favorite',
  template:`
    <div class="folder-box">
      <div class="folder">
        <div class="icon">
          <span class="d-flex" [inlineSVG]="icon"></span>
        </div>
        <span class="tc-grey">
          {{ folderName }}
        </span>
      </div>
      <app-favorite (onClick)="onFavorite.emit($event)"
                    [isStarred]="isFavorite"
      ></app-favorite>
    </div>
  `,
  styles:[
    `
      .folder-box {
        min-width: 100%;
        min-height: 100px;
        padding: 1rem 1.5rem;
        display: inline-flex;
        border-radius: 8px;
        justify-content: space-between;
        background-color: #F9F9F9;
      }

      .folder-box .folder{
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
      }

      ::ng-deep .folder-box .icon svg {
        width: 40px!important;
        height: 40px!important;
      }
    `
  ]
})
export class FolderFavoriteComponent {
  @Input()
  public folderName!: string;

  @Input()
  public type!: string

  @Input()
  public isFavorite!: boolean;

  @Output()
  public onFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();

  get icon(): string{
    return getFileIcon(this.type)
  }
}
