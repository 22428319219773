import { Directive, HostListener } from "@angular/core";

@Directive({
    standalone: true,
    selector: '[stopPropagate]'
})
export class SportPropagate {
    constructor() {
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        event.stopPropagation();
    }
}