<div class="dialog-40 dialog-detail">
  <header>
    <h2 mat-dialog-title>{{ title }}</h2>
    <div>
      <ng-content select="[buttonTopRight]"></ng-content>
    </div>
  </header>
  <div class="mat-dialog-content">
    <ng-content select="[dialogBody]"></ng-content>
  </div>
</div>

<mat-dialog-actions align="start" class="mat-dialog-action">
  <ng-content select="[actButton]"></ng-content>
</mat-dialog-actions>
