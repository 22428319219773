<mat-form-field appearance="outline" class="full-width roam-field white-bg">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select [placeholder]="placeholder" #roamSelect
              class="roam-menu"
              [panelClass]="'select-narrow'"
              [hideSingleSelectionIndicator]="true"
              (selectionchange)="onValueChange($event)"
  >
    <mat-option *ngFor="let item of times" [value]="item">
      <p class="roam-text">{{ item.view }}</p>
    </mat-option>
  </mat-select>
  <button matSuffix mat-icon-button type="button" (click)="closeSelect()">
    <span [inlineSVG]="icon.clock.src"></span>
  </button>
</mat-form-field>
