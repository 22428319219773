import { Component, Input } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  standalone: true,
  selector: 'app-roam-title-text',
  imports: [InlineSVGModule],
  template:`
    <div class="align-center gap-12 svg-28">
      <span class="d-flex icon" [inlineSVG]="icon"></span>
      <h5 class="font-18px fw-500">
        {{ text }}
      </h5>
    </div>
  `,
  styles: [`
    ::ng-deep .svg-28 svg {
      height: 28px;
      width: 28px;
    }
  `]
})
export class RoamTitleTextComponent {

  @Input()
  public icon!: string;

  @Input()
  public text!: string;
}
