import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-doc-shared-more',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './doc-shared-more.component.html',
  styleUrls: ['./doc-shared-more.component.scss']
})
export class DocSharedMoreComponent {
  public icon = iconLib;
}
