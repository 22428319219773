import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-label-icon',
  template:`
    <div class="align-center gap-10" [class]="class">
      <span class="svg-24 d-flex" *ngIf="icon" [inlineSVG]="icon"></span>
      <h6 class="font-16px fw-500">
        {{ text }}
      </h6>
    </div>
  `,
})
export class TextLabelIconComponent {

  @Input()
  public icon: string = '';

  @Input()
  public text: string = ''

  @Input()
  public class: string = '';
}
