import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  standalone: true,
  imports:[RoamIconComponent],
  selector: 'app-floating-menu-bar',
  templateUrl: './floating-menu-bar.component.html',
  styleUrls: ['./floating-menu-bar.component.scss']
})
export class FloatingMenuBarComponent {

  @Input()
  public name: string = '';

  @Input()
  public icon: any = '';

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public iconLib = iconLib

}
