import { TitleCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { roamLogo } from "@app/core/const/roam-icon";

@Component({
  standalone: true,
  selector: "app-roam-layout-container-menu",
  templateUrl: "./roam-layout-container-menu.component.html",
  styleUrls: ["./roam-layout-container-menu.component.scss"],
  imports: [TitleCasePipe]
})
export class RoamLayoutContainerMenuComponent {
  @Input()
  public isFull: boolean = false;

  @Input()
  public logo: boolean = false;

  @Input()
  public title: string = "";

  public roamLogo = roamLogo;
}
