<div >
  <div [formGroup]="form">
    <input-dropdown
      cdkOverlayOrigin
      #openAddEvent="cdkOverlayOrigin"
      (click)="isDropdown = !isDropdown"
      name="Select Knowledge Base"
      formControlName="kbControl"
      [ctr]="form.controls['kbControl']"
    />
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="openAddEvent"
  [cdkConnectedOverlayOpen]="isDropdown"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayWidth]="dropdownWidth"
  [cdkConnectedOverlayPositions]="dropdownPosition"
  [cdkConnectedOverlayViewportMargin]="7"
  (backdropClick)="isDropdown= false"
>
  <div class="dropdown">

    <div class="p-16">
      <app-input-search-one (onSearch)="searchFile($event)"></app-input-search-one>
    </div>

    <div class="knowledge-list">

      <div class="plr-16">
        <ng-container *ngIf="!isCreateNew">
          <create-new-plus (onClick)="isCreateNew = true"></create-new-plus>
        </ng-container>

        <ng-container *ngIf="isCreateNew">
          <app-create-new-knowledge-base (onClose)="isCreateNew = false"
                                         (onSave)="handleNewKnowledgeBase($event)"
          ></app-create-new-knowledge-base>
        </ng-container>
      </div>

      <div class="mt-8">
        <div *ngIf="(kbAction.kbList | async); else emptyList">
          <ng-container *ngFor="let kb of kbAction.kbList | async">
            <div class="align-center gap-12 c-pointer ptb-8 file-name-base"
                 [class.selected]="kb.isChecked"
                 (click)="selectedFile(kb);"
            >
              <roam-icon name="diamond" color="primary" size="32"></roam-icon>
              <div>
                <app-text-label-desc [label]="kb.name"
                                     [desc]="kb.createdAt | dateTime : 'dateMMMMDDComa'"
                                     [black]="true"
                ></app-text-label-desc>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyList>
  <div class="plr-16 ptb-10">No Knowledge Base Available</div>
</ng-template>
