<div class="checkbox-user justify-between w-100">

  <div *ngIf="multiple" class="round-checkbox reverse">
    <mat-checkbox (change)="onChange.emit()" [checked]="checked">
      <a role="link" class="item-link">
        <div class="item-avatar">
          <app-image-view [image]="image"
                          [height]="'44px'" [width]="'44px'" [rounded]="true"
                          [placeholderImage]="'user'"
                          [placeholderHeight]="'44px'"
          ></app-image-view>
        </div>
        <div class="item-name" >
          <div>
        <span class="name tc-black">
          {{ name }}
        </span>
          </div>
          <div>
            <div class="item-content">
          <span class="text">
            {{ infoGroup }}
          </span>
            </div>
          </div>
        </div>
      </a>
    </mat-checkbox>
  </div>
</div>
