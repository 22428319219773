import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild, input } from '@angular/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { FileCheckedComponent } from '../file-checked/file-checked.component';
import { fileFavoriteConfig, fileSliderConfig } from '@app/pages/associations/shared/const/association-swiper.const';
import { SwiperOptions } from 'swiper';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, SwiperModule, FileCheckedComponent, RoamIconComponent],
  selector: 'file-slider',
  template: `
    @if(files() && files().length > 0) {
    <div class="position-relative">
      <swiper #swiper [config]="swiperConfig" [slidesPerView]="2">
        @for(file of files(); track $index) {
          <ng-template swiperSlide class="swiper-card">
            <file-checked [url]="file.url"
                          [fileName]="file.name"
                          [date]="file.updatedAt"
            />
          </ng-template>
        }
      </swiper>
      <button
        class="btn-swiper-nav"
        (click)="slidePrev()"
      >
        <roam-icon name="chevron-left" size="28"/>
      </button>

      <button
        class="btn-swiper-nav next deg-180"
        (click)="slideNext()"
      >
        <roam-icon name="chevron-left" size="28"/>
      </button>
    </div>
  } @else {
    <div class="tc-grey font-14px">There are no files for this record.</div>
  }
  `,
  styles: ``
})
export class FileSliderComponent implements AfterViewInit {
  files = input<any[]>([]);
  views = input<number>();
  isBreakpoints = input<boolean>(true);

  swiperConfig: SwiperOptions = fileSliderConfig;
  breakpoints = {
    1370: {
      slidesPerView: 4.2,
      spaceBetween: 20,
    },
    1250: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    1000: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 2
    },
  }

  @ViewChild('swiper', { static: false })
  public swiper?: SwiperComponent;

  ngAfterViewInit(): void {
    if(this.isBreakpoints()) {
      this.swiperConfig.breakpoints = this.breakpoints;
    } else {
      this.swiperConfig.slidesPerView = this.views();
      this.swiperConfig.breakpoints = {};
    }
   
    this.swiper?.updateSwiper(this.swiperConfig);
  }

  slideNext(){
    this.swiper?.swiperRef.slideNext(100);
  }

  slidePrev(){
    this.swiper?.swiperRef.slidePrev(100);
  }
}
