import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextDirectiveModule } from "@app/shared/directives/text-directive/text-directive.module";

@Component({
  selector: 'app-text-status',
  standalone: true,
  imports: [
    CommonModule,
    TextDirectiveModule
  ],
  template: `
    <span appFontColor class="fw-500" [status]="status"
          [isBackground]="isBackground"
          [isBgOpacity]="isBgOpacity"
          [class.bg-status]="isBgOpacity || isBackground"
    >
      {{ statusName }}
    </span>
  `,
  styles: [
      `
      .bg-status {
        padding: 2px 8px;
        border-radius: 4px;
      }
    `
  ]
})
export class TextStatusComponent {

  @Input()
  public status!: string;

  @Input()
  public statusName!: string;

  @Input()
  public isBackground!: boolean;

  @Input()
  public isBgOpacity!: boolean;
}
