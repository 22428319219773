import { NgForOf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { IActionListSelection } from "@app/shared/interfaces/action-list-selection.interface";


export const actionListMultiple = [
  {
    id: 'link',
    icon: iconLib.link.src
  },
  {
    id:'download',
    icon: iconLib.download.src
  },
  {
    id: 'send',
    icon: iconLib.send.src
  },
  {
    id: 'share',
    icon: iconLib.share.src
  },
  {
    id: 'delete',
    icon: iconLib.bin.src
  }
];



@Component({
  standalone: true,
  imports: [NgForOf],
  selector: 'app-action-list-selection',
  templateUrl: './action-list-selection.component.html',
  styleUrls: ['./action-list-selection.component.scss']
})
export class ActionListSelectionComponent {

  @Input()
  public icons: IActionListSelection[] = actionListMultiple;

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public click(id: string): void {
    this.onClick.emit(id);
  }
}
