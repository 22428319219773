import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { ImageFile } from "@app/shared/interfaces/image-file.interface";
import {
  fileExtensionCollection,
  getFileType,
} from "../file-config/shared/utils/file-word-masking.util";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { AttachmentCardComponent } from "../attachment-card/attachment-card.component";
import { FileCardComponent } from "../file-config/file-card/file-card.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: "app-attachment-upload-file",
  templateUrl: "./attachment-upload.component.html",
  styleUrls: ["./attachment-upload.component.scss"],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AttachmentCardComponent,
    FileCardComponent,
  ]
})
export class AttachmentUploadFileComponent {
  @Input()
  public fileCard: boolean = false;

  @Input()
  public insideUpload: boolean = true;

  @Output()
  public onUploadedFiles: EventEmitter<ImageFile[]> = new EventEmitter<
    ImageFile[]
  >();

  @ViewChild("fileInput", { static: false }) fileInput!: ElementRef;

  public icon = iconLib;
  public selectedFiles: ImageFile[] = [];

  public openFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  // public selectedFile(event: any): void {
  //   const File = (event.target as HTMLInputElement).files;
  //   this.files.push(File![0]);
  //   this.onUploadedFiles.emit(this.files);
  //   event.target.value = null;
  // }

  public deleteFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.onUploadedFiles.emit(this.selectedFiles);
  }

  public onFileSelected(event: any): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imageUrl = e.target.result;
        if (imageUrl && typeof imageUrl === "string") {
          const extension = file.name.split(".").pop() || "";
          const imageFile: ImageFile = {
            file: file,
            url: imageUrl,
            extension: extension,
            isImage: !fileExtensionCollection.includes(extension),
            type: getFileType(extension),
          };
          this.selectedFiles.push(imageFile);
          console.log(this.selectedFiles);
          this.onUploadedFiles.emit(this.selectedFiles);
        }
      };
      reader.readAsDataURL(file);
    }
    // event.target.value = null;
  }
}
