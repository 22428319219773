import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { ButtonComponent } from "@app/shared/components/button/button/button.component";
import { FormControl, FormsModule } from "@angular/forms";

@Component({
  selector: 'add-decision-stamp',
  standalone: true,
  imports: [CommonModule, RoamSelectComponent, ButtonComponent, FormsModule],
  template:`
    <div class="add-decision-stamp">
      <h6 class="fw-500 tc-black">Add Decision Stamp</h6>
      <div class="d-flex gap-12 mt-12">
        <div class="flex-1">
          <app-roam-select placeholder="Choose Decision"
                           [items]="items"
                           [(ngModel)]="selectedDecision"
          ></app-roam-select>
        </div>
        <app-button label="Submit" (onClick)="submit.emit(selectedDecision.value)"></app-button>
      </div>
    </div>
  `,
  styles:[`
    .add-decision-stamp {
      padding: 20px 30px;
      background: #F9F9F9;
      border-radius: 8px;
    }
  `]
})
export class AddDecisionStampComponent {

  @Input()
  public items: any[] = [];

  @Output()
  public submit: EventEmitter<string | null> = new EventEmitter<string | null>();

  public selectedDecision = new FormControl();

}
