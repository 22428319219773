<div class="posting">
  <header>
    <div class="align-center gap-16">
      <app-image-view
        [rounded]="true"
        [image]="image"
        [width]="'44px'" [height]="'44px'"></app-image-view>
      <div>
        <div class="user">{{ name }}</div>
        <div class="fw-300 font-14px">{{ timeStamp }}</div>
      </div>
    </div>
    <div class="c-pointer">
      <img [src]="icon.more.src" [alt]="icon.more.alt">
    </div>
  </header>
  <article>
    <div class="post">
      <div class="text" *ngIf="textEllipsis">
        {{ text | ellipsis : 200  }}
        <span class="tc-primary fw-400 c-pointer" (click)="seeMore($event)">see more</span>
      </div>
      <div class="text-more"
           [style.display]="!textEllipsis ? 'block' : 'none'">
        {{ text }}
      </div>
    </div>
    <div class="engage">
      <div class="align-center gap-9">
        <span class="like">
          <img [src]="icon.liked.src" [alt]="icon.liked.alt">
        </span>
        <span>
          {{ countLiked }}
        </span>
      </div>
      <div>
        <span class="mr-16">{{ countComment }} comments</span>
        <span>{{ countShared }} share</span>
      </div>
    </div>
  </article>
  <footer>
    <div>
      <img [src]="icon.like.src" [alt]="icon.like.alt">
      <span>Like</span>
    </div>
    <div>
      <img [src]="icon.comment.src" [alt]="icon.comment.alt">
      <span>Comment</span>
    </div>
    <div>
      <img [src]="icon.share.src" [alt]="icon.share.alt">
      <span>Share</span>
    </div>
  </footer>
</div>
