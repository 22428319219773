import {
  ComponentFactoryResolver,
  Injectable,
  ViewContainerRef,
  NgModule,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable } from "rxjs";

type InyectionPosition = "before" | "after";

@Injectable()
export class ComponentLoaderFeature {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  loadComponent(
    component: any,
    viewContainerRef: ViewContainerRef,
    inputs: InputSubmitEvent[] = [],
    outputs: OutputComponentData[] = [],
    position: InyectionPosition = "before",
  ): any {
    if (!component) return;
    this.unloadComponent(viewContainerRef);
    const componentRef = viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(component),
    );
    const instance: any = componentRef.instance;

    this.appendComponent(
      viewContainerRef.element.nativeElement,
      componentRef.location.nativeElement,
      position,
    );

    inputs.forEach((input) => {
      if (input) instance[input.id] = input.value;
    });

    outputs.forEach((output) => {
      let componentOutput: Observable<any> = instance[output.id];
      if (!componentOutput) return;
      componentOutput.subscribe((res) => output.emitter.emit(res));
    });

    componentRef.injector.get(ChangeDetectorRef).detectChanges();

    return instance;
  }

  unloadComponent(viewContainerRef: ViewContainerRef) {
    viewContainerRef.clear();
  }

  private appendComponent(
    parent: HTMLElement,
    child: HTMLElement,
    position: InyectionPosition,
  ) {
    if (!parent.firstElementChild) return;
    if (position === "before") {
      parent.firstElementChild.appendChild(child);
    } else {
      parent.firstChild?.insertBefore(child, parent.firstChild.firstChild);
    }
  }
}

export interface InputSubmitEvent {
  value: any;
  id: string;
}

export interface OutputComponentData {
  emitter: EventEmitter<any>;
  id: string;
}

@NgModule({ providers: [ComponentLoaderFeature] })
export class ComponentLoaderServiceModule {}
