import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: any, length: number | null = 50): any {
    return ellipsisText(value, length);
  }
}

export const ellipsisText = (text: string, length: number | null = 140) => {
  const ending = '...';

  if (length === undefined) {
    length = 30;
  }

  if(length) {
    if (text?.length > length) {
      return text.substring(0, length) + ending;
    }
  }

  return text || '-';
}
