<div mat-button class="mat-button-icon share" [matMenuTriggerFor]="menu">
  <img [src]="icon.more.src" [alt]="icon.more.alt" />
</div>


<mat-menu #menu="matMenu" class="menu-action" yPosition="above">
  <div class="menu-action-item" *ngFor="let icon of iconCollections"
       (click)="onClick.emit(icon.id)"
       [ngStyle]="{display: (icon.id === 'ai' && isFile) || icon.id !== 'ai' ? 'flex' : 'none'}"
  >
    <img [src]="icon.icon" alt="'action'">
    <span>{{ icon.name }}</span>
  </div>
</mat-menu>
