export const AssociationTableKeyConst = [
  "name",
  "city",
  "unitsCount",
  "managerName",
];

export const AssociationTableLabelConst = [
  "Association",
  "Location",
  "Units",
  "Manager",
];

export const AssociationBoardDirectorTableKeyConst = [
  "user.name",
  "user.customers.0.units.0.address",
  "position",
  "appointedDate",
  "termLength",
];

export const AssociationBoardDirectorTableLabelConst = [
  "Name",
  "Address",
  "Position",
  "Appointed Date",
  "Term Length",
];

export const AssociationAmenitiesTableKeyConst = [
  "description",
  "address",
  "name",
  "createdAt",
  "status",
  "private",
];

export const AssociationAmenitiesTableLabelConst = [
  "Description",
  "Address",
  "Name",
  "Date",
  "Status",
  "Private",
];

export const AssociationCommitteesTableKeyConst = [
  "user.name",
  "user.customers.0.units.0.address",
  "committee.name",
  "startDate",
];

export const AssociationCommitteesTableLabelConst = [
  "Name",
  "Address",
  "Committee",
  "Start Date",
];

export const AssociationDocumentTableKeyConst = [
  "name",
  "date",
  "size",
  "selection",
];

export const AssociationDocumentTableLabelConst = [
  "Name",
  "Updated",
  "Size",
  "",
];

export const AssociationMeetingTableKeyConst = ["name", "attended", "date"];

export const AssociationMeetingTableLabelConst = [
  "Description",
  "Attended",
  "Date",
];

export const AssociationMotionTableKeyConst = [
  "date",
  "unitName",
  "description",
  "statusName",
  "isPrivate",
];

export const AssociationMotionTableLabelConst = [
  "Date",
  "Address",
  "Description",
  "Status",
  "Private",
];

export const AssociationUnitTableKeyConst = [
  "address",
  "customers.0.name",
  "customers.0.totalBalance",
];

export const AssociationUnitTableLabelConst = ["address", "Owner", "Balance"];
