import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { IKnowledgeBase } from "@app/shared/interfaces/knowledge-base.interface";

@Injectable({
  providedIn: 'root'
})
export class KbActionService {

  public kbUpdate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public kbList: BehaviorSubject<IKnowledgeBase[]> = new BehaviorSubject<IKnowledgeBase[]>([])

  updateKb(newValue: any): void {
    this.kbUpdate.next(newValue);
  }

  getKbUpdates(): Observable<any> {
    return this.kbUpdate.asObservable();
  }
}
