import { NgModule } from "@angular/core";
import { TextCellTableComponent } from "./components/text-cell-table/text-cell-table.component";
import { CellTableComponent } from "./cell-table.component";
import { CommonModule } from "@angular/common";
import { DateCellTableComponent } from "./components/date-cell-table/date-cell-table.component";

@NgModule({
  declarations: [
    DateCellTableComponent,
    CellTableComponent,
    TextCellTableComponent,
  ],
  exports: [CellTableComponent, TextCellTableComponent],
  imports: [CommonModule],
})
export class CellTableModule {}
