import { Component } from '@angular/core';
import { CommonModule } from "@angular/common";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";
import { ROAM_IMAGE_SVG } from "@app/core/const/app-roam.const";
import { INameIcon } from "@app/shared/interfaces/id-name.interface";
import { RoamButtonComponent } from '../../button/roam-button/roam-button.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  standalone: true,
  selector: 'app-llc-stripe-dialog',
  templateUrl: './llc-stripe-dialog.component.html',
  styles:[
    `
      .dialog-content {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 2rem;
      }

      .dialog-body {
        min-height: 45vh;
      }

      .dialog-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        text-align: center;
      }
    `
  ],
  imports:[
    CommonModule,
    InlineSVGModule,
    RoamButtonComponent
  ]
})
export class LlcStripeDialogComponent {
  public stripLogo: string = `${ROAM_IMAGE_SVG}/strip-logo.svg`;
  public icon = iconLib;
  public lists: INameIcon[] = [
    {
      icon: `${ROAM_IMAGE_SVG}/ic-lock.svg`,
      name: 'Roam Stay, LLC uses Stripe to connect your account'
    },
    {
      icon: `${ROAM_IMAGE_SVG}/ic-shield-check.svg`,
      name: 'Data is encrypted for your protection'
    },
    {
      icon: `${ROAM_IMAGE_SVG}/ic-link-black.svg`,
      name: 'You can Disconnect at any time'
    }
  ]

  constructor(private dialogRef: MatDialogRef<LlcStripeDialogComponent>) {}

  public close(): void {
    this.dialogRef.close()
  }

  public confirmLLLStripe(): void {
    this.dialogRef.close(true)
  }
}
