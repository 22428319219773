import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-roam-label',
  template:`
    <div class="roam-label" [class]="labelClass">
      {{ label || 'Label' }}
    </div>
  `,
  styles:[
    `
      .roam-label {
        padding: 4px 8px;
        display: inline-flex;
        background: #DFE6F9;
        border-radius: 4px;
        color: #295AD8;
        font-size: 14px;
      }
    `
  ]
})
export class RoamLabelComponent {

  @Input()
  public label!: string;

  @Input()
  public labelClass!: string;
}
