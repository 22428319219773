<div class="file-view"
     (mouseenter)="isHover = true"
     (mouseleave)="isHover = false">

  <header>
    <div class="d-flex align-center gap-8">
      <roam-icon [name]="url | fileExtension"></roam-icon>
      <div class="line-height-1-2">

        <div class="title"> {{ name }}</div>

        <span class="font-12px tc-grey">
          {{ date | date }}
        </span>

      </div>
    </div>

    <div [style.visibility]="isHover ? 'visible' : 'hidden'">
      <file-view-action/>
    </div>
  </header>

  <div class="preview" [class.bg-glight]="!(url | fileExtension)">
    <roam-icon [name]="url | fileExtension" size="100"></roam-icon>
  </div>
</div>
