import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { CommonModule } from "@angular/common";
/*
* SAMPLE: Maintenance Detail
* */
@Component({
  standalone: true,
  imports:[CommonModule, RoamIconComponent],
  selector: 'app-roam-card-two',
  templateUrl: './roam-card-two.component.html',
  styleUrls: ['./roam-card-two.component.scss']
})
export class RoamCardTwoComponent {

  @Input()
  public title!: string;

  @Input()
  public action: string = '';

  @Input()
  public icon: string = '';

  @Input()
  public titleIcon!: string;

  @Input()
  public btnAction: boolean = false;

  @Output()
  public onAct: EventEmitter<Event> = new EventEmitter<Event>();
}
