import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GptService {
  private apiUrl = environment.apiUrl

  constructor(
    private httpClient: HttpClient,
  ) { }
  askGpt(question: string): Observable<any> {
    const body = {
      question: question
    };
    return this.httpClient.post<any>(this.apiUrl +'/gpt/ask', body, {
      withCredentials: true,
    });
  }
  askMistral(question: string): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl +'/gpt/mistral', { question });
  }
  createKnowledgeBase(body: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl +'/knowledge-bases', body, {
      withCredentials: true,
    });
  }
  getKnowledgeBases(property: string = ""): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl +'/knowledge-bases', {
        withCredentials: true,
        headers: {
          property,
        },
      },
    );
  }
  queryKnowledgeBase(modelName: string, knowledgeBaseSlugs: string[] | null = null, question: string): Observable<any> {
    const body: any = {
      modelName: modelName,
      query: question
    };
    if (knowledgeBaseSlugs) {
      body.knowledgeBaseSlugs = knowledgeBaseSlugs;
    }
    return this.httpClient.post<any>(this.apiUrl +'/assistant/query', body, {
      withCredentials: true,
    });
  }
  queryDocument(modelName: string, documentUrl: string, question: string): Observable<any> {
    const body: any = {
      modelName: modelName,
      documentUrl: [
        documentUrl,
      ],
      query: question
    };
    return this.httpClient.post<any>(this.apiUrl +'/assistant/query-documents', body, {
      withCredentials: true,
    });
  }
}
