<div class="attachment" [class.justify-start]="selectedFiles.length > 0">
  <input
    type="file"
    #fileInput
    id="uploadFile"
    (change)="onFileSelected($event)"
  />
  <ng-container *ngIf="selectedFiles.length === 0">
    <span class="fw-600 tc-primary">Drag here</span> or
    <button mat-button class="upload-button" (click)="openFileUpload()">
      <img [src]="icon.upload.src" alt="Upload Icon" class="mr-8" />
      <span class="fw-400">Upload File</span>
    </button>
  </ng-container>

  <ng-container *ngIf="insideUpload">
    <ng-container *ngIf="selectedFiles.length > 0">
      <div *ngFor="let file of selectedFiles; let file_i = index">
        <ng-container *ngIf="!fileCard">
          <app-attachment-card
            [fileName]="file.file.name"
            [type]="file.file.name.split('.').pop() || ''"
            [showDelete]="true"
            (onDelete)="deleteFile(file_i)"
          ></app-attachment-card>
        </ng-container>
        <ng-container *ngIf="fileCard">
          <div class="list-card position-relative">
            <button
              mat-icon-button
              class="close-chip-button"
              (click)="deleteFile(file_i)"
            >
              <mat-icon class="icon-close">close</mat-icon>
            </button>
            <app-file-card
              *ngIf="file.type!.toString() != 'image'; else showImage"
              [name]="file.file.name"
              [type]="file.file.name.split('.').pop() || ''"
              [full]="true"
            ></app-file-card>
            <ng-template #showImage>
              <img [src]="file.url" alt="" />
            </ng-template>
          </div>
        </ng-container>
      </div>
      <div
        class="tc-primary fw-500 c-pointer text-underline"
        (click)="openFileUpload()"
      >
        + Add File
      </div>
    </ng-container>
  </ng-container>
</div>
