<div class="d-gap">
  <ng-container>
    <div class="d-gap shared" *ngIf="isSelected">
      <app-icon-round
        *ngIf="showAIAction"
        [alt]="icon.roamAiBlack.alt"
        [src]="icon.roamAiBlack.src"
        (onClick)="clickAI.emit()"
      />

      <app-icon-round
        [alt]="icon.diamond.alt"
        [src]="icon.diamond.src"
        (onClick)="clickKnowledgeBase.emit()"
      />

      <app-icon-round
        [alt]="icon.download.alt"
        [src]="icon.download.src"
        (onClick)="clickDownload.emit()"
      />

      <app-icon-round
        [alt]="icon.send.alt"
        [src]="icon.send.src"
        (onClick)="clickSend.emit()"
      />

      <app-icon-round
        [alt]="icon.share.alt"
        [src]="icon.share.src"
        (onClick)="clickShare.emit()"
      />

      <app-icon-round
        [alt]="icon.bin.alt"
        [src]="icon.bin.src"
        (onClick)="clickDelete.emit()"
      />
    </div>

    <div class="position-relative more"
         [class.d-none]="!isSelected"
    >
      <app-icon-round
        [alt]="icon.more.alt"
        [src]="icon.more.src"
        cdkOverlayOrigin
        #openSharedMore="cdkOverlayOrigin"
        (onClick)="showMore = !showMore"
      />

    </div>
  </ng-container>

  <div class="d-gap">
    <div class="act-layout">
      <app-icon-round
        [src]="fileLayout ? icon.layout.src : icon.list.src"
        [alt]="'layout'"
        (onClick)="switchLayout()"
      />
    </div>

    <button class="btn btn-o-black align-center pl-10" *ngIf="!isSelected">
      <mat-icon style="font-size: 20px; margin-top: 4px; margin-right: 4px">
        add
      </mat-icon>

      <span class="fw-600">New</span>
    </button>
  </div>
</div>


<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="openSharedMore"
  [cdkConnectedOverlayOpen]="showMore"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayPositions]="dropdownLeft"
  [cdkConnectedOverlayViewportMargin]="7"
  (backdropClick)="showMore= false"
>
  <app-doc-shared-more></app-doc-shared-more>
</ng-template>


